// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import {ajaxPerso, meta, shopify_promo_object, socketPerso, confirmation_modal, shopifyOptions, hasOnlyDefaultVariant} from "../../../fnc.js"
// import ModalSpecialPromo from '../ModalSpecialPromo.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'

const refConst = require("../../../constants.js").v

const styleList = {
	menu: (provided) => ({
		...provided,
		zIndex: 99999
	}),
}

const metaPromoPageName = "specialPromoPage"

var initialState = {
	selectedBrand: {},
	selectedCountry: "",
	selectedMenu: "",
	nbrVideoMax: 2,
	navTabVideoSelected: 0,
	linkVideo: [],
	brandObject: false,
	tagsGroup: [],
	tagsGroupSelection: [], // {idGroup1: [stringSelected], idGroup2: [stringSelected]}
	itemFinale: false,
	published: 1,
	hideAplus: false,
	rated: [],
	isDraft: false,
	_isMounted: false,
	promoPageListItems: [],
	promoMetaObj: null,
	metaPromoExist: null,
	price: 0.0,
	compareAtPrice: null,
	needSkuPrefix: true,
	otherTags: [],
	inventoryPolicy: "continue", //"deny",
	bulletsArea: "",
	extendedBody: "",
	shortDesc: "",
}

const idMetaRatedEio = "5454161346626"

function FormShopifyListing(props) {

	const [selectedBrand, setSelectedBrand] = useState(initialState.selectedBrand);
	const [selectedCountry, setSelectedCountry] = useState(initialState.selectedCountry);
	const [selectedMenu, setSelectedMenu] = useState(initialState.selectedMenu);
	const [nbrVideoMax, setNbrVideoMax] = useState(initialState.nbrVideoMax);
	const [navTabVideoSelected, setNavTabVideoSelected] = useState(initialState.navTabVideoSelected);
	const [linkVideo, setLinkVideo] = useState(initialState.linkVideo);
	const [brandObject, setBrandObject] = useState(initialState.brandObject);
	const [tagsGroup, setTagsGroup] = useState(initialState.tagsGroup);
	const [tagsGroupSelection, setTagsGroupSelection] = useState(initialState.tagsGroupSelection);
	const [itemFinale, setItemFinale] = useState(initialState.itemFinale);
	const [published, setPublished] = useState(initialState.published);
	const [hideAplus, setHideAplus] = useState(initialState.hideAplus);
	const [rated, setRated] = useState(initialState.rated); // List of rated variant ID's
	const [isDraft, setIsDraft] = useState(initialState.isDraft);
	const [promoPageListItems, setPromoPageListItems] = useState(initialState.promoPageListItems.slice(0));
	const [promoMetaObjOriginal, setPromoMetaObjOriginal] = useState(initialState.promoMetaObj);
	const [promoMetaObj, setPromoMetaObj] = useState(initialState.promoMetaObj);
	const [metaPromoExist, setMetaPromoExist] = useState(initialState.metaPromoExist);
	const [compareAtPrice, setCompareAtPrice] = useState(initialState.compareAtPrice); // Usefull to keep the price before promo.
	const [originalPrice, setOriginalPrice] = useState(initialState.price);
	const [price, setPrice] = useState(initialState.price);
	const [needSkuPrefix, setNeedSkuPrefix] = useState(initialState.needSkuPrefix);
	const [otherTags, setOtherTags] = useState(initialState.otherTags);
	const [inventoryPolicy, setInventoryPolicy] = useState(initialState.inventoryPolicy);
	const [bulletsArea, setBulletsArea] = useState(initialState.bulletsArea); // For haus only
	const [extendedBody, setExtendedBody] = useState(initialState.extendedBody); // For haus only
	const [shortDesc, setShortDesc] = useState(initialState.shortDesc); // For haus only

	useEffect(() => {
		initialState._isMounted = true;

		return () => initialState._isMounted = false
	}, [])

	useEffect(() => {
		
		emptyFormTotal()
		
	}, [props.shopSelected])

	useEffect(() => {
		/**
		 * Need to be place here cause the componant ModalSpecialPromo get disconnected and reconnected for every change happening here.
		 */
		if(props.shopSelected.id)
			meta.fetch({
				namespace: metaPromoPageName,
				key: metaPromoPageName,
				shop: props.shopSelected.id,
			}, (r) => {
				console.info(`Fetched fetch_meta 'metaPromoPageName':`, r)
				if(r.success){
					//let val = r.res.length > 0? JSON.parse(r.res[0].value) : Object.assign({}, props.initialState.promoPageListItems)
					let val = r.res.length > 0? JSON.parse(r.res[0].value) : []
					if(!val) val = []
					if(val && typeof val === "object" && "items" in val)	val = val.items;
					if(!Array.isArray(val))	val = [val];

					setPromoPageListItems(val)
				}
			})
	}, [props.shopSelected])

	useEffect(() => {
		// SYSTEM REPLACED
		//  For our older product imported to shopify, there are product without prefix yet,
		//  These product have an lookup without prefix as well so we need to make sure to not place
		//  a prefix on them.
		if(Object.keys(selectedBrand).length > 0 && props.updateMode){
			if(!selectedBrand.prefix){
				setNeedSkuPrefix(false)
			}else{
				setNeedSkuPrefix(true)
				props.updateMode.variants.forEach(v => {
					console.info("TESTssss", v.sku);
					if(v.sku.indexOf(selectedBrand.prefix) !== -1){
						setNeedSkuPrefix(false)
					}
				})
			}
		}
	}, [props.updateMode, selectedBrand])


	useEffect(() => {
		/**
		 * Pull the brand object associated if it exist
		 */
		if(selectedBrand.id)
			fetchBrandObject( selectedBrand.id, r => {
				if(!initialState._isMounted)	return false
				console.info('Fetched brand Object:', r);
				if(r.success && r.data && "data" in r.data){
					let objData = r.data.data
					setBrandObject(objData)
				}else
					setBrandObject(initialState.brandObject)
			})

	}, [selectedBrand])

	useEffect(() => {
		if(props.variantMode && props.shopSelected){
			shopifyOptions({
				shop: props.shopSelected.id
			}, (r) => {
				console.info(`Fetched shopifyOptions`, r);

				props.setGlobalOptions(r.success && r.data && r.data.length > 0? r.data : [])
			})
		}
	}, [props.shopSelected, props.updateMode, props.variantMode])

	useEffect(() => {
		/**
		 * Pull each tags group
		 */
		fetchTagsGroup( r => {
			if(!initialState._isMounted)	return false
			console.info('Fetched tagsGroup Object:', r);
			if(r.success && r.res){
				setTagsGroup(r.res)
			}else
				setTagsGroup(initialState.tagsGroup)
		})

	}, [props.shopSelected])

	useEffect(() => {
		/**
		 * Fetch the rated shipping for EIO
		 */
		if(props.shopSelected && props.updateMode && props.shopSelected.id == refConst.id_store_eio){

			fetchAzureRated({}, r => {
				if(!initialState._isMounted)	return false
				console.info('Fetched fetchAzureRated:', r);
				setRated(r.success && r.res.length > 0? r.res.map(o => {
					return o.RowKey
				}) : false)
			})

		}

	}, [props.shopSelected, props.updateMode])

	useEffect(() => {
		
		let variantBasicStructure = fillVariantSpecifics()
		// console.info("variantBasicStructurevariantBasicStructure", variantBasicStructure);
		props.setVariantsSpecificsToSubmit(variantBasicStructure)
		props.setSelectedVariant(variantBasicStructure.length > 0? variantBasicStructure[1].valFilled.position : 1)

	}, [props.productOptions])

	useEffect(() => {

		$('.ctnNavBrandTmp .nav-item').on("click", (e) => {
			// We close the opened tab if a click was made on the open tab
			let currentTg = $($(e)[0].currentTarget)
			console.log(currentTg.is(".active"));
			
			if(currentTg.is(".active")){
				console.log($("#" + currentTg.attr("aria-controls")));
				
				currentTg.removeClass("active").attr("aria-selected", "false")
				$("#" + currentTg.attr("aria-controls")).removeClass("show").removeClass("active")
				return false;
			}
		})
	})

	useEffect(() => {
		$('[data-toggle]').tooltip({
			placement: "bottom"
		}).tooltip('update')
	})

	useEffect(() => {

		let variantList = props.variantsSpecificsToSubmit.map(v => {
			return v.valFilled
		})

		setInventoryPolicy(variantList[props.selectedVariant].inventory_policy? variantList[props.selectedVariant].inventory_policy : initialState.inventoryPolicy)
		
	}, [props.variantsSpecificsToSubmit, props.selectedVariant])

	const removePrefixFromSku = (sku) => {

		return selectedBrand && selectedBrand.prefix && sku.indexOf(selectedBrand.prefix) !== -1?
			sku.substr(selectedBrand.prefix.length) : sku
	}

	const fillVariantSpecifics = () => {

		let variantBasicStructure = generateVariantObjectList()

		// console.info("variantBasicStructurevariantBasicStructure", JSON.stringify(variantBasicStructure));

		if(props.updateMode){
			/**
			 * If ever we change the options for an existing item already with variant:
			 * 	- We need to change the variant options for each variant already in place.
			 *    - A warning message need to be given saying that the option will be changed for the current variants.
			 */
			props.updateMode.variants.forEach((v, realPosition) => {

				let vBs = variantBasicStructure[v.position],
					optionsVar = [v.option1, v.option2, v.option3]

				if(vBs)
					optionsVar = vBs.options

				v.sku = removePrefixFromSku(v.sku)
				if(!("mpn" in v))
					v.mpn = ""

				variantBasicStructure[v.position] = {
					options: optionsVar,
					modified: false,
					valFilled: Object.assign(v, {
						id: v.id,
						title: optionsVar.filter(val => {
							if(val)	return val
						}).join(", "),
						position: realPosition+1,
						// price: parseFloat(v.price),
						// quantity: parseInt(v.inventory_quantity) > 0? parseInt(v.inventory_quantity) : 0,
						// mpn: "",
						// sku: v.sku,
						// barcode: v.barcode,
						// weight: v.weight_unit != "lb"? v.weight : v.weight / 16, // (oz to lbs)
						// position: parseInt(v.position),
					})
				}
			})
		}

		variantBasicStructure = prefillVariantBaseOnFirst(variantBasicStructure)
		/* props.setVariantsSpecificsToSubmit(variantBasicStructure.length === 0? initialState.defaultVariant.slice(0) : variantBasicStructure)
		props.setSelectedVariant(variantBasicStructure.length > 0? variantBasicStructure[1].valFilled.position : 1) */
		return variantBasicStructure.length === 0? initialState.defaultVariant.slice(0) : variantBasicStructure
	}

	const prefillVariantBaseOnFirst = (varSpecificList) => {
		/**
		 * If the first variant has some data filled like the price and weight,
		 * we will cppy these data over the the other variants to speed up the created,
		 */

		if(varSpecificList.length > 1){
			let propsToCopy = {}
			varSpecificList.forEach((v, pos) => {
				if(Object.keys(propsToCopy).length === 0){
					propsToCopy.weight = v.valFilled.weight
					propsToCopy.price = v.valFilled.price
					propsToCopy.weight_unit = v.valFilled.weight_unit
				}else{
					if(!v.valFilled.weight)
						v.valFilled.weight = propsToCopy.weight
					if(!v.valFilled.price)
						v.valFilled.price = propsToCopy.price
					if(!v.valFilled.weight_unit)
						v.valFilled.weight_unit = propsToCopy.weight_unit
				}
			})
		}
		console.info("varSpecificListvarSpecificList", varSpecificList);
		return varSpecificList
	}

	const triggerShippingRateChange = (needToAdd) => {

		if(!props.shopSelected || !props.updateMode || props.shopSelected.id != refConst.id_store_eio)
			return

		if(!needToAdd){

			confirmation_modal(props, (closeModal) => {

				props.loading(true);

				rated.forEach(varId => {

					ajaxPerso({
						"api": "azure",
						"trigger": "delete",
						"accName": "shopifyapp",
						"table": "shipping",
						"partition": "itemsSelected",
						"key": varId,
					}, (r) => {
						props.loading(false);
						console.info("Fetching Del shipping rate", r);
						if(r.success){
							closeModal()
							setRated([])
							props.info({"success": "Rated shipping deleted"})
						}else
							props.info({"error": "Something went wrong"})
					});	
				})

			}, null, <div>
				<u>Immediate action</u>
			</div>)
		}else{

			let varList = props.variantsSpecificsToSubmit;

			varList.forEach(v => {

				if(v.valFilled.id){
					let listAdded = []
					ajaxPerso({
						"api": "azure",
						"trigger": "insert",
						"accName": "shopifyapp",
						"table": "shipping",
						"data": JSON.stringify({
							"PartitionKey": "itemsSelected",
							"RowKey": String(v.valFilled.id),
							"idMeta": String(idMetaRatedEio),
							"idProduct": String(props.updateMode.id),
							"shopName": props.shopSelected.account_name
						})
					}, (r) => {
						console.info("Fetching New shipping rate", r);
						if(r.success){
							listAdded.push(String(props.updateMode.variants[props.selectedVariant-1].id))
							setRated(listAdded)
							props.info({"success": "Rated shipping added"})
						}else
							props.info({"error": "Something went wrong"})
					});	
				}
			})
		}
	}

	const fetchBrandObject = (idBrand, callback) => {
		let args = {}
		if(idBrand)
			args.idProductBrand = idBrand

		ajaxPerso( Object.assign({
			"api": "eio",
			"trigger": "getBrandTemplate",
			//"data": JSON.stringify(args)
		}, args), callback)
	}

	const fetchTagsGroup = (callback) => {
		ajaxPerso( Object.assign({
			"api": "shopify",
			"trigger": "fetchTagsGroup",
			"shop": props.shopSelected.id,
		}, {}), callback)
	}

	const fetchAzureRated = (args, callback) => {
		if(args)	args = {};
		ajaxPerso( Object.assign({
			"api": "azure",
			"trigger": "fetch",
			"accName": "shopifyapp",
			"table": "shipping",
			// "fields": "",
			"filters": `shopName eq '${props.shopSelected.account_name}' and idMeta eq '${idMetaRatedEio}' and idProduct eq '${props.updateMode.id}'`,
			"top": 1,
		}, args), callback)
	}

	const fetchItemFromFinale = (sku, callback) => {

		if(!sku)	return false

		ajaxPerso( {
			"api": "finale",
			"trigger": "getItemsBySku",
			"sku": JSON.stringify([(selectedBrand.prefix? selectedBrand.prefix : "") + sku]),
		}, callback)
	}

	const fetchItemFromShopify = (sku, callback) => {

		if(!sku)	return false

		ajaxPerso( {
			"api": "shopify",
			"trigger": "getItemFromSku",
			"sku": JSON.stringify([(selectedBrand.prefix? selectedBrand.prefix : "") + sku]),
			"shop": props.shopSelected.id,
		}, callback)
	}

	const emptyFormTotal = (callback) => {
		setInventoryPolicy(initialState.inventoryPolicy)
		props.setTitle(props.initialState.title)
		setBulletsArea(initialState.bulletsArea)
		setOtherTags(initialState.otherTags)

		// setPrice(props.initialState.price)
		setOriginalPrice(initialState.price)
		setCompareAtPrice(initialState.compareAtPrice)
		setMetaPromoExist(initialState.metaPromoExist)
		setPromoMetaObjOriginal(initialState.promoMetaObj)
		setPromoMetaObj(initialState.promoMetaObj)
		setItemFinale(initialState.itemFinale)
		setBrandObject(initialState.brandObject)
		setSelectedBrand(Object.assign({}, initialState.selectedBrand))
		setSelectedMenu(initialState.selectedMenu)
		setSelectedCountry(initialState.selectedCountry)
		setTagsGroupSelection(Object.assign({}, initialState.tagsGroupSelection))
		//setDesc(initialState.desc)
		setShortDesc(initialState.shortDesc)
		// setFreeShipping(initialState.freeShipping)
		setLinkVideo(initialState.linkVideo)

		if($("#inputSortDesc").val())
			$('#inputSortDesc').summernote("code", "");
		/* if($("#inputDesc").val())
			$('#inputDesc').summernote("code", ""); */
		for (let i = 0; i < nbrVideoMax; i++)
			if($("#inputDescVideo_" + i).val())
				$("#inputDescVideo_" + i).summernote("code", "");

		props.setBinList(props.initialState.images)
		props.setBinListDropzone(props.initialState.imagesDropzone)
		//props.setDsData(props.initialState.dsData)
		props.setHtmlDT(props.initialState.htmlDT)
		setHideAplus(initialState.hideAplus)

		if(callback)	callback()
		props.emptyForm();
	}

	const updateMeta = (itemId, obj, callback) => {

		if(Object.keys(obj).length === 0)	return false

		let hasToBeDeleted = !("hasToBeDeleted" in obj)? obj.value == null || obj.value === ''? true : false    :   obj.hasToBeDeleted

		let objJson = {
			"api": "shopify",
			"trigger": "request",
			"method": hasToBeDeleted? 'DELETE' : 'PUT',
			"page": 'products/'+itemId+'/metafields/' + obj.id + '.json',
			"shop": props.shopSelected.id,
			"body": !hasToBeDeleted? JSON.stringify(
				{
					"metafield": {
						"id": obj.id,
						"value": obj.value,
						"type": obj.type
					}
				}) : null
		};

		console.log("---- " + (hasToBeDeleted? "Delete" : "Update"));
		console.log(objJson);
		
		ajaxPerso( objJson, callback)
	}

	const formSelectionCollection = () => {

		return(
			<div className="form-group hasTooltip" { ...(props.msgInfo.menu? { "data-toggle": "tooltipFormError", "title": props.msgInfo.menu } : {}) }>
				<label htmlFor="exampleInputEmail1">Collection*</label>
				<Select
					styles={styleList}
					onChange={(selectedOption) => setSelectedMenu(selectedOption.value)}
					options={(() => {
						let valueReturn = []
						props.menu.forEach(m => {
							valueReturn.push( {
								value: m.last.name,
								label: (m.last.site? `[${m.last.site}] ` : "") + m.inline
							})
						})
						return valueReturn
					})()}
					value={[{value: selectedMenu, label: selectedMenu}]}
					defaultValue={[{value: selectedMenu, label: selectedMenu}]}
					isSearchable={true}
					//isMulti={true}
				/>
				{/* { props.msgInfo.menu? <div className="text-danger">{props.msgInfo.menu}</div> : ''} */}
			</div>
			
		)
	}

	const showVariants = () => {

		let objPassThrough = {
			pos: 0
		}
		let opPos = 0
		let op = props.productOptions[opPos]

		if(!op){
			return <div className="text-center">
				Please select first the option group you need for your item.
			</div>
		}

		if(opPos < props.productOptions.length-1){

			return op.values.map(opV => {

				let nextOptionsLvl1 = props.productOptions[opPos+1]
				
				return nextOptionsLvl1.values.map(nextOpV1 => {

					if(opPos+1 < props.productOptions.length-1){

						return op.values.map(opV => {
			
							let nextOptionsLvl2 = props.productOptions[opPos+2]
							
							return nextOptionsLvl2.values.map(nextOpV2 => {
								
								return <div key={`${opV}, ${nextOpV1}, ${nextOpV2}_key`}>
									<span>{`${opV}, ${nextOpV1}, ${nextOpV2}`}</span>
									{
										htmlInputOptionFilling(nextOptionsLvl2, [opV, nextOpV1, nextOpV2], objPassThrough)
									}
								</div>
							})
						})
			
					}else{ // If there is only one set of options
			
						return <div key={`${opV}, ${nextOpV1}_key`}>
							<span>{`${opV}, ${nextOpV1}`}</span>
							{
								htmlInputOptionFilling(nextOptionsLvl1, [opV, nextOpV1, null], objPassThrough)
							}
						</div>
					}
				})
			})

		}else if(op){ // If there is only one set of options

			return op.values.map(opV => {
				return <div key={`${opV}_key`}>
					<span>{opV}</span>
					{
						htmlInputOptionFilling(op, [opV, null, null], objPassThrough)
					}
				</div>
			})
		}
	}

	const generateVariantObjectList = () => {

		let opPos = 0
		let op = props.productOptions[opPos]
		let basicInfoOngoing = []
		let posIntheArray = 1

		if(opPos < props.productOptions.length-1){

			op.values.map(opV => {

				let nextOptionsLvl1 = props.productOptions[opPos+1]
				
				nextOptionsLvl1.values.map(nextOpV1 => {

					if(opPos+1 < props.productOptions.length-1){

						op.values.map(opV => {
			
							let nextOptionsLvl2 = props.productOptions[opPos+2]
							
							nextOptionsLvl2.values.map(nextOpV2 => {
								
								basicInfoOngoing[posIntheArray] = {
									options: [opV, nextOpV1, nextOpV2],
									modified: false,
									valFilled: {
										mpn: "",
										title: [opV, nextOpV1, nextOpV2].filter(val => {
											if(val)	return val
										}).join(", "),
										price: "",
										inventory_quantity: "",
										sku: "",
										barcode: "",
										position: posIntheArray,
										weight: "",
										weight_unit: ""
									}
								}
								posIntheArray++
							})
						})
			
					}else{ // If there is only one set of options
			
						basicInfoOngoing[posIntheArray] = {
							options: [opV, nextOpV1, null],
							modified: false,
							valFilled: {
								mpn: "",
								title: [opV, nextOpV1, null].filter(val => {
									if(val)	return val
								}).join(", "),
								price: "",
								inventory_quantity: "",
								sku: "",
								barcode: "",
								position: posIntheArray,
								weight: "",
								weight_unit: ""
							}
						}
						posIntheArray++
					}
				})
			})

		}else{ // If there is only one set of options
			if(op)
				op.values.map(opV => {

					basicInfoOngoing[posIntheArray] = {
						options: [opV, null, null],
						modified: false,
						valFilled: {
							mpn: "",
							title: [opV, null, null].filter(val => {
								if(val)	return val
							}).join(", "),
							price: "",
							inventory_quantity: "",
							sku: "",
							barcode: "",
							position: posIntheArray,
							weight: "",
							weight_unit: ""
						}
					}
					posIntheArray++
				})

		}

		return basicInfoOngoing
	}

	const htmlInputOptionFilling = (opObj, optionNameList, oPassThrough) => {

		let indexlist = ++oPassThrough.pos
		let optionList = optionNameList.map(optionName => {
			return optionName
		})

		let variantsAlreadyIn = []
		variantsAlreadyIn = props.variantsSpecificsToSubmit.map(o => {
			return o.options.join("|||")
		})

		if(variantsAlreadyIn.indexOf(optionList.join("|||")) === -1)
			props.variantsSpecificsToSubmit[indexlist] = {
				options: optionList,
				modified: false,
				valFilled: {
					mpn: "",
					title: optionList.filter(val => {
						if(val)	return val
					}).join(", "),
					price: "",
					inventory_quantity: "",
					sku: "",
					barcode: "",
					position: indexlist,
					weight: "",
					weight_unit: ""
				}
			}

		if(!props.variantsSpecificsToSubmit[indexlist])	return ""

		return <div key={`${optionNameList.join("|")}_htmlInputOptionFilling`} className="mr-3">
			<div className="d-inline-block ml-3"  { ...(props.msgInfo[`price${indexlist}`]? { "data-toggle": "tooltipFormError", "title": props.msgInfo[`price${indexlist}`] } : {}) }>
				<div className="input-group mb-3">
					<input type="text" className="form-control" defaultValue={props.variantsSpecificsToSubmit[indexlist].valFilled.price > 0.0? props.variantsSpecificsToSubmit[indexlist].valFilled.price : ""} placeholder={"Price"} onChange={(e) => {
						props.variantsSpecificsToSubmit[indexlist].modified = true
						props.variantsSpecificsToSubmit[indexlist].valFilled.price = parseFloat(e.currentTarget.value)
					}}/>
					<div className="input-group-append">
						<span className="input-group-text">Price</span>
					</div>
				</div>
			</div>
			<div className="d-inline-block ml-3">
				<div className="input-group mb-3">
					<input type="text" className="form-control" defaultValue={props.variantsSpecificsToSubmit[indexlist].valFilled.inventory_quantity > 0? props.variantsSpecificsToSubmit[indexlist].valFilled.inventory_quantity : "0"} placeholder={"Quantity"} onChange={(e) => {
						props.variantsSpecificsToSubmit[indexlist].modified = true
						props.variantsSpecificsToSubmit[indexlist].valFilled.inventory_quantity = parseInt(e.currentTarget.value)
					}}/>
					<div className="input-group-append">
						<span className="input-group-text">Quantity</span>
					</div>
				</div>
			</div>
			<div className="d-inline-block ml-3" { ...(props.msgInfo[`sku${indexlist}`]? { "data-toggle": "tooltipFormError", "title": props.msgInfo[`sku${indexlist}`] } : {}) }>
				<div className="input-group mb-3">
					<input type="text" className="form-control" defaultValue={props.variantsSpecificsToSubmit[indexlist].valFilled.sku? props.variantsSpecificsToSubmit[indexlist].valFilled.sku : ""} placeholder={"Sku"} onChange={(e) => {
						props.variantsSpecificsToSubmit[indexlist].modified = true
						props.variantsSpecificsToSubmit[indexlist].valFilled.sku = e.currentTarget.value
					}}/>
					<div className="input-group-append">
						<span className="input-group-text">Sku</span>
					</div>
				</div>
			</div>
			<div className="d-inline-block ml-3" { ...(props.msgInfo[`mpn${indexlist}`]? { "data-toggle": "tooltipFormError", "title": props.msgInfo[`mpn${indexlist}`] } : {}) }>
				<div className="input-group mb-3">
					<input type="text" className="form-control" defaultValue={props.variantsSpecificsToSubmit[indexlist].valFilled.mpn? props.variantsSpecificsToSubmit[indexlist].valFilled.mpn : ""} placeholder={"Mpn"} onChange={(e) => {
						props.variantsSpecificsToSubmit[indexlist].modified = true
						props.variantsSpecificsToSubmit[indexlist].valFilled.mpn = e.currentTarget.value
					}}/>
					<div className="input-group-append">
						<span className="input-group-text">MPN</span>
					</div>
				</div>
			</div>
			<div className="d-inline-block ml-3" { ...(props.msgInfo[`barcode${indexlist}`]? { "data-toggle": "tooltipFormError", "title": props.msgInfo[`barcode${indexlist}`] } : {}) }>
				<div className="input-group mb-3">
					<input type="text" className="form-control" defaultValue={props.variantsSpecificsToSubmit[indexlist].valFilled.barcode? props.variantsSpecificsToSubmit[indexlist].valFilled.barcode : ""} placeholder={"UPC"} onChange={(e) => {
						props.variantsSpecificsToSubmit[indexlist].modified = true
						props.variantsSpecificsToSubmit[indexlist].valFilled.barcode = e.currentTarget.value
					}}/>
					<div className="input-group-append">
						<span className="input-group-text">Barcode</span>
					</div>
				</div>
			</div>
			<div className="d-inline-block ml-3" { ...(props.msgInfo[`lbs${indexlist}`]? { "data-toggle": "tooltipFormError", "title": props.msgInfo[`lbs${indexlist}`] } : {}) }>
				<div className="input-group mb-3">
					<input type="text" className="form-control" defaultValue={props.variantsSpecificsToSubmit[indexlist].valFilled.weight? props.variantsSpecificsToSubmit[indexlist].valFilled.weight : ""} placeholder={"Lbs"} onChange={(e) => {
						props.variantsSpecificsToSubmit[indexlist].modified = true
						props.variantsSpecificsToSubmit[indexlist].valFilled.weight = parseFloat(e.currentTarget.value)
					}}/>
					<div className="input-group-append">
						<span className="input-group-text">Lbs</span>
					</div>
				</div>
			</div>
		</div>
	}

	const addOptionToList = (newData) => {

		//	[{"name":"Color","values":["Blue","Black"]},{"name":"Size","values":["155","159"]}]
		let cpOptions = props.productOptions.slice(0)
		let newO = JSON.parse(newData.value)
		let alreadyIn = null
		cpOptions.forEach((o, pos) => {
			if(o.id === newO.id)
				alreadyIn = pos
		})

		// If one of the element as the same title, then we update it with the newly selected one.
		let alreadyInName = null
		cpOptions.forEach((o, pos) => {
			if(o.name == newO.name)
				alreadyInName = pos
		})

		if(alreadyInName !== null){
			addOptionToListWarning((closeModal) => {
				cpOptions[alreadyInName] = newO
				props.setProductOptions(cpOptions)
				if(closeModal)	closeModal()
			})
		}else{

			if(cpOptions.length >= 3){
				props.info({error: "Limited to 3 options, Please delete one before adding this one."})
			}else if(alreadyIn === null){
				
				addOptionToListWarning((closeModal) => {
					/**
					 * If we added a second, we need to check if the first is the default, if so we need to remove it.
					 * Also if we add > than 1, we need to check if the first has a price/weight, if so we need to copy the dat over to the new variants.
					 */
					
					if(hasOnlyDefaultVariant(cpOptions)){
						cpOptions = []
					}

					cpOptions.push(newO)
					props.setProductOptions(cpOptions)
					if(closeModal)	closeModal()
				})
			}
		}
	}

	const addOptionToListWarning = (callback) => {

		if(!props.updateMode || hasOnlyDefaultVariant(props.productOptions)){
			callback(null)
		}else
			confirmation_modal(props, callback, null, <div className="smallText font-weight-normal">
				The existing variant will be mapped to the newly selected option by position order. This change will require that you control each variant to make sure the data reflect the choosen option.
				<u className="mt-2 d-block">
					If you are trying to go back from a multi variant to a single variant base item, you will need to remove  each options group and select only the one named &quot;Title - Default title&quot;
				</u>
			</div>)
	}

	const removeOptionToList = (oToR) => {

		let alreadyIn = null
		props.productOptions.forEach((o, pos) => {
			if(o.id === oToR.id)
				alreadyIn = pos
		})

		if(alreadyIn !== null){
			addOptionToListWarning((closeModal) => {
				props.productOptions.splice(alreadyIn, 1)
				props.setProductOptions(props.productOptions.slice(0))
				if(closeModal)	closeModal()
			})
		}
	}

	const removeOptionValueToList = (option, valPos) => {
		option.values.splice(valPos, 1)
		props.setProductOptions(props.productOptions.slice(0))
	}

	const sectionVariant = (oToR) => {

		return <div className="text-center border border-info mb-3">
			{
				<div className="custom-control custom-switch m-3 w-100">
					<input type="checkbox" className="custom-control-input" id="customSwitchVarMode" checked={props.variantMode? true : false} onChange={(e) => { props.setVariantMode(e.target.checked) }}/>
					<label className="custom-control-label noselect pointer" htmlFor="customSwitchVarMode">Activate Multi Variant Mode</label>
				</div>
			}
			{
				props.variantMode? <div className="text-left">
					<div className="m-3 pt-2 pb-2 bg-info border text-light mb-3 text-center pointer btnVariantsOver" onClick={quickEditVariants}>
						Quick edit variants
					</div>
					<div className="ml-3 mr-3 pt-2 pb-2 mb-0 bg-info border text-light mb-3 text-center pointer btnVariantsOver" onClick={editOptions}>
						Edit option group
					</div>
					{showAddingOptionsSection()}
				</div> : ""
			}
		</div>
	}

	const quickEditVariants = () => {

		props.resetVariantError()

		props.modal({
			show: true,
			title: "Variants Editor",
			options: {
				width: "modal-xl",
				body: {
					className: "text-nowrap overflow-auto mr-3",
				}
			},
			html: () => {
				return <div className="text-center font-weight-bold">
					<div className="alert alert-primary text-center">
						When you are done editing, click on &quot;Done&quot; and then submit your product to save the changes.
					</div>
					{
						showVariants()
					}
				</div>
			}
			,ok: {
				title: "Done",
				fnc: (popup, close) => {
					close()
					// quickEditSubmitData()
				}
			},
			return: {
				title: "Close"
			}
		})
	}

	const quickEditSubmitData = () => {

		console.info("variantsSpecificsToSubmit33", props.variantsSpecificsToSubmit);

		props.info({success: "To apply your change, click on Submit."})
	}

	const editOptions = () => {

		props.resetVariantError()

		props.modal({
			show: true,
			title: "Option Editor",
			options: {
				// width: "modal-xl",
				body: {
					className: "text-nowrap overflow-auto",
				}
			},
			html: () => {
				return <div className="text-center font-weight-bold">
					{
						showOptions()
					}
				</div>
			}
			,ok: {
				title: "Done",
				fnc: (popup, close) => {
					close()
				}
			},
			return: {
				title: "Close"
			}
		})
	}

	const showOptions = () => {

		// Option object to send to shopify: [{"name":"Color","values":["Blue","Black"]},{"name":"Size","values":["155","159"]}]
		return <div className="text-center">
			Coming soon
		</div>
	}

	const showAddingOptionsSection = () => {

		let optionsIdList = props.productOptions.map(o => {
			return o.id
		})
	
		var optionsToShow = []
		props.globalOptions.forEach((option) => {
			if(optionsIdList.indexOf(option.id) === -1)
				optionsToShow.push({value: JSON.stringify(option), label: `${option.name} - ${option.values.join(', ')}`})
		})

		return <div className="p-2 pb-3">
			<div className="p-3">
				<label htmlFor="forTitle">Options group*</label>
				<Select
					styles={refConst.styleList}
					onChange={addOptionToList}
					// onChange={(selectedOption) => setSelectedOption(JSON.parse(selectedOption.value))}
					options={optionsToShow}
					// defaultValue={[selectedBrand? {value: JSON.stringify(selectedBrand), label: selectedBrand.name || ""} : {value: "", label: "-"} ]}
					// value={[{value: JSON.stringify(selectedBrand), label: selectedBrand.name || ""}]}
					isSearchable={true}
				/>
			</div>
			<nav>
				<div className="nav nav-tabs" id="nav-tab" role="tablist">
					{
						props.productOptions.map((o, pos) => {
							
							return <a key={pos+"_keyGrpTitleNav"} onClick={() => { props.setNavGrpOptionsSelected(props.navGrpOptionsSelected == pos? null : pos); }} className={
								(props.navGrpOptionsSelected === pos? "active" : "") + 
								" nav-item nav-link text-center"} id={pos + "_navBtn"} data-toggle="tab" href={"#" + pos + "_navCtn"} role="tab" aria-controls={pos + "_navCtn"} aria-selected={false}>
								<div className="d-inline mr-3">{o.name}</div>
								<button type="button" className="bg-white border-0 d-inline" onClick={() => removeOptionToList(o)}>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
										<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
										<path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
									</svg>
								</button>
							</a>
						})
					}
				</div>
			</nav>
			<div className={(props.navGrpOptionsSelected !== null? "d-block" : "d-none") + " tab-content p-3"} id="nav-tabContent">
				{
					props.productOptions.map((option, opPos) => {

						let listOptionsChoice = option.values.map((oV, oVPos) => {
							return <div key={`${oVPos}_optionValue`} className="pt-2 pb-2">
								<button type="button" className="btn btn-secondary" onClick={() => removeOptionValueToList(option, oVPos)}>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
										<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
										<path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
									</svg>
								</button>
								<span className="ml-3">{oV}</span>
							</div>
						})

						listOptionsChoice.push(<div key={`${props.navGrpOptionsSelected}_optionValue_add`} className="mt-2">
							<div className="input-group">
								<div className="input-group-prepend">
									<span className="input-group-text">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
											<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
										</svg>
									</span>
								</div>
								<input type="text" className="form-control"/>
								<div className="input-group-append">
									<button type="button" className="btn btn-primary" onClick={(e) => {

										let elem = e.currentTarget.parentElement.previousSibling,
											val = elem.value.trim()
										if(val.length > 0){
											props.productOptions[props.navGrpOptionsSelected].values.push(val)
											props.setProductOptions(props.productOptions.slice(0))
											elem.value = ""
										}

									}}>Add</button>
								</div>
							</div>
						</div>)
							
						return <div key={opPos+"_keyGrpOptionCtnNav"} className={(props.navGrpOptionsSelected === opPos? "active" : "") + " tab-pane fade show"} id={opPos + "_navCtn"} role="tabpanel" aria-labelledby={opPos + "_navBtn"} style={{maxHeight: "200px", overflowY: "auto"}}>
							{
								listOptionsChoice
							}
						</div>
					})
				}
			</div>
		</div>
	}

	/* const htmlInputOptionFilling_v2 = (opObj, optionNameList, oPassThrough) => {

		let indexlist = ++oPassThrough.pos
		let optionList = optionNameList.map(optionName => {
			return optionName
		})

		let variantsAlreadyIn = []
		variantsAlreadyIn = props.variantsSpecificsToSubmit.map(o => {
			return o.options.join("|")
		})

		if(variantsAlreadyIn.indexOf(optionList.join("|")) === -1)
			props.variantsSpecificsToSubmit[indexlist] = {
				options: optionList,
				modified: false,
				valFilled: {
					title: optionList.filter(val => {
						if(val)	return val
					}).join(", "),
					price: null,
					inventory_quantity: null,
					sku: null,
					barcode: null,
					position: indexlist,
				}
			}

		props.setVariantsSpecificsToSubmit(Object.assign({}, props.variantsSpecificsToSubmit))
	} */

	const formSelectVariant = () => {

		return(
			props.variantMode? <div className="form-group hasTooltip pl-3 pr-3 pb-3 pt-2 bg-primary rounded" { ...(props.msgInfo.variantError? { "data-toggle": "tooltipFormError", "title": props.msgInfo.variantError } : {}) }>
				<label className="text-light">Variant*</label>
				<Select
					className=""
					styles={styleList}
					onChange={(selectedOption) => props.setSelectedVariant(parseInt(selectedOption.value))}
					options={(() => {
						let valueReturn = []
						props.variantsSpecificsToSubmit.forEach((v, vPos) => {
							valueReturn.push( {
								value: vPos,
								label: `[${vPos}] - ` + v.options.filter((oValue) => {
									if(oValue)	return oValue
								}).join(", ") + (v.valFilled.id? ` - (Exist)` : ` - (Need to create)`) + (v.modified? ` [Modified]` : ``)
							})
						})
						return valueReturn
					})()}
					value={props.selectedVariant !== null? [{
						value: props.selectedVariant,
						label: props.variantsSpecificsToSubmit[props.selectedVariant].options.filter(oValue => {
							if(oValue)	return oValue
						}).join(", ")
					}] : null}
					isSearchable={true}
				/>
				{/* { props.msgInfo.menu? <div className="text-danger">{props.msgInfo.menu}</div> : ''} */}
			</div> : ""
			
		)
	}

	const formSelectionBrand = () => {
		//console.log("brandObject", brandObject)
		let partView = {"nav": [], "ctn": []};
		if(brandObject)
			brandObject.forEach(obj => {
				partView.nav.push( <a key={obj.model+"_navBrandTmp"} className="nav-item nav-link" id={"nav_"+obj.model+"-tab"} data-toggle="tab" href={"#nav_"+obj.model} role="tab" aria-controls={"nav_"+obj.model} aria-selected="true">{obj.model}</a> )
				partView.ctn.push( <div key={obj.model+"_CtnBrandTmp"} className="border border-top-0 tab-pane p-3 fade" id={"nav_"+obj.model} role="tabpanel" aria-labelledby={"#nav_"+obj.model+"-tab"}  dangerouslySetInnerHTML={{__html: obj.value}}/> )
			})

		return(
			<div className="form-group hasTooltip" { ...(props.msgInfo.brand? { "data-toggle": "tooltipFormError", "title": props.msgInfo.brand } : {}) }>
				{
					props.isAutoCreated? <div className="alert alert-warning mt-3 text-wrap">
						This product was automatically created by the system. you are limited in the data you can update.
					</div> : ''
				}
				<label htmlFor="exampleInputEmail1">Brand*</label>
				<Select
					styles={styleList}
					onChange={(selectedOption) => setSelectedBrand(JSON.parse(selectedOption.value))}
					options={props.brands.map((obj) => {
						return {value: JSON.stringify(obj), label: obj.name}
					})}
					defaultValue={[selectedBrand? {value: JSON.stringify(selectedBrand), label: selectedBrand.name || ""} : {value: "", label: "-"} ]}
					value={[{value: JSON.stringify(selectedBrand), label: selectedBrand.name || ""}]}
					isSearchable={true}
					//isMulti={true}
				/>
				{/* {<CreatableSelect
					styles={ styleList }
					placeholder="Select or Create a tag"
					onChange={(selectedOption) => {
						console.info("Search Info: ", selectedOption);
						setSelectedBrand(JSON.parse(selectedOption.value))
					}}
					options={props.brands.map((obj) => {
						return {value: JSON.stringify(obj), label: obj.name}
					})}
					defaultValue={[selectedBrand? {value: JSON.stringify(selectedBrand), label: selectedBrand.name || ""} : {value: "", label: "-"} ]}
					value={[{value: JSON.stringify(selectedBrand), label: selectedBrand.name || ""}]}
					isSearchable={true}
				/>} */}
				{/* { props.msgInfo.brand? <div className="text-danger">{props.msgInfo.brand}</div> : ''} */}
				{partView.nav.length > 0? <div style={{maxWidth: "600px"}} className="ctnNavBrandTmp overflow-auto mt-3">
					<nav>
						<div className="nav nav-tabs" id="nav-tab" role="tablist">
							{ partView.nav }
						</div>
					</nav>
					<div className="tab-content text-wrap text-break overflow-auto" id="nav-tabContent" style={{maxWidth: "600px", maxHeight: "250px"}}>{ partView.ctn }</div>
				</div> : "" }
			</div>)
	}
	const formSelectionCountry = () => {

		return(
			<div className="col">
				<label htmlFor="exampleInputEmail1">Country of origin</label>
				<Select
					styles={styleList}
					onChange={(selectedOption) => setSelectedCountry(selectedOption.value)}
					options={props.countries.map((obj) => {
						return {value: obj? obj.country_name : '', label: obj? obj.country_name + ' ('+obj.country_code+')' : '-'}
					})}
					value={[{value: selectedCountry? selectedCountry : "", label: selectedCountry? selectedCountry : "-"}]}
					defaultValue={[{value: selectedCountry? selectedCountry : "", label: selectedCountry? selectedCountry : "-"}]}
					isSearchable={true}
					//isMulti={true}
				/>
			</div>)
	}

	const formDefineVideoInfo = () => {

		return(
			<div>
				<nav>
					<div className="nav nav-tabs" id="nav-tab" role="tablist">
						{
							(() => {
								let outputNavVideo = []
								for (let index = 0; index < nbrVideoMax; index++) {
									outputNavVideo.push(<a key={index+"_videoCtn"} onClick={() => { setNavTabVideoSelected(index) }} className={
										(navTabVideoSelected === index? "active" : "") + 
										" nav-item nav-link text-center"} id={index + "_navBtnVideoCtn"} data-toggle="tab" href={"#" + index + "_navCtnVideo"} role="tab" aria-controls={index + "_navCtnVideo"} aria-selected={false}>
										<div>video {index+1}</div>
									</a>)
								}
								return outputNavVideo
							})()
						}
					</div>
				</nav>
				<div className="tab-content" id="nav-tabContent">
					{
						(() => {
							let outputNavVideo = []
							for (let index = 0; index < nbrVideoMax; index++) {
								outputNavVideo.push(<div key={index+"_keyGrpCtnNav"} className={(navTabVideoSelected === index? "active" : "") + " tab-pane fade show mt-3"} id={index + "_navCtnVideo"} role="tabpanel" aria-labelledby={index + "_navBtnVideoCtn"}>
									<div className="form-group">
										<label htmlFor="inputDesc">Description Video {index+1}</label>
										<textarea type="text" className="form-control descVideo" id={"inputDescVideo_"+index}></textarea>
										{ props.msgInfo.descVideo && props.msgInfo.descVideo[index]? <div className="text-danger">{props.msgInfo.descVideo[index]}</div> : ''}
									</div>
									<div className="form-group">
										<label htmlFor={"inputYoutubeLink_"+index}>
											Youtube link {index+1}
											<span className="ml-3 tooltipInfo" data-html="true" title="<span>
												on a youtube page, click on 'Share' and then copy the link.
											</span>" data-toggle="tooltip"><FontAwesomeIcon icon={faQuestionCircle} style={{fontSize: "23px"}}/></span>
										</label>
										<input type="text" placeholder="on a youtube page, click on 'Share' then 'copy'" className="form-control" id={"inputYoutubeLink_"+index} rows="3" value={linkVideo[index]? linkVideo[index]  : ""} onChange={(e) => {
											let valLink = e.target.value
											let lkv = linkVideo.slice(0)
											lkv[index] = valLink.trim().length > 0? "https://www.youtube.com/embed" + valLink.substring(valLink.lastIndexOf("/")) : ""
											setLinkVideo(lkv)
										}}/>
										{ props.msgInfo.linkVideo && props.msgInfo.linkVideo[index]? <div className="text-danger">{props.msgInfo.linkVideo[index]}</div> : ''}
									</div>
								</div>)
							}
							return outputNavVideo
						})()
					}
				</div>
			</div>)
	}

	async function getBase64(file) {
		let res = await new Promise(function(resolve, reject) {
			var reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = function () {
				resolve(reader.result)
			};
			reader.onerror = function (error) {
				reject(error)
			};
		})

		return res;
	}

	const submitForm = (e, fncControlData, fncTriggerRequest) => {
		e.preventDefault();
		///////props.loading(true);
		//props.info({success: "okkkkk"})

		var tabPromise = [],
			tabObjBins = [],
			tabObjBinsLinkImgs = [],
			tabObjBinsLinkFiles = []
			
		fncControlData((res) => {
			
			if(res.passed){
				props.binList.forEach(bin => {
					
					/* if(!(bin.typeBin in objBinGrouped))
						objBinGrouped[bin.typeBin] = [] */
	
					if(bin.isFromShopify){
						bin.position = bin.pos + 1
	
						if(bin.typeBin == "img")
							tabObjBinsLinkImgs.push( bin )
						if(bin.typeBin == "file")
							tabObjBinsLinkFiles.push( bin )
					}else{
						tabObjBins.push(bin) // keep object order
						tabPromise.push(getBase64(bin))
					}
				})
				
				if(props.binList.length > 0){
					if(tabPromise.length > 0)
						Promise.all(tabPromise).then((base64Bins) => {
							let imgsBin = [],
								filesBin = []
	
							base64Bins.forEach((base64, index) => {
								base64Bins[index] = { attachment: base64 }
								base64Bins[index].position = tabObjBins[index].pos + 1
								base64Bins[index].name = tabObjBins[index].name
	
								if(tabObjBins[index].typeBin == "img")
									imgsBin.push(base64Bins[index])
								if(tabObjBins[index].typeBin == "file")
									filesBin.push(tabObjBins[index])
							})
							
							// Add every files already saved to the new binary objects.
							imgsBin = imgsBin.concat(tabObjBinsLinkImgs)
							filesBin = filesBin.concat(tabObjBinsLinkFiles)
							
							imgsBin.sort((obj1, obj2) => {
								if(obj1.pos > obj2.pos)
									return 1
								return -1
							})
	
							filesBin.sort((obj1, obj2) => {
								if(obj1.pos > obj2.pos)
									return 1
								return -1
							})
	
							fncTriggerRequest(imgsBin, filesBin);
						})
					else
						fncTriggerRequest(tabObjBinsLinkImgs, tabObjBinsLinkFiles);
				}else{
					fncTriggerRequest([], []);
				}
				
			}else{
				props.loading(false);
				console.warn("error for tooltip:", res.msg);
				props.setMsgInfo(res.msg)
			}
		})

		console.log("Submit");
	}

	const htmlDescVideo = () => {
		let output = []

		console.warn("htmlDescVideo", linkVideo);

		linkVideo.forEach((linkVideo, index) => {
			let descVde = $("#inputDescVideo_" + index ).val()
			console.log("descVde", descVde);
			console.log("linkVideo", linkVideo);

			if(descVde == "<p>&nbsp;</p>")
				descVde = null
			
			if(/* descVde.trim().length > 0 &&  */linkVideo.trim().length > 0){
				output.push({
					"description": descVde,
					"link": linkVideo
				})
			}
		})

		// We add the version number if ever we need to adjust the Liquid Theme
		return {
			data: output,
			version: props.version
		}
	}

	const htmlTagsGroup = () => {
		
		return <div className="d-flex flex-row flex-wrap">
			{tagsGroup.map((tagGrp, index) => {
				return <div className="form-group w-50 p-2" key={tagGrp.id + "_tagGroup"}>
					<label>{tagGrp.name}</label>
					<Select
						styles={styleList}
						onChange={(selectedOption) => {
							let valChoosen = [];
							let cp = Object.assign({}, tagsGroupSelection)

							if(selectedOption){
								if(!cp[tagGrp.id])
									cp[tagGrp.id] = [];

								if(parseInt(tagGrp.multi_select))
									selectedOption.forEach(obj => {
										if(obj.value)
											valChoosen.push(obj.value)
									})
								else{
									if (selectedOption.value)
										valChoosen.push(selectedOption.value);
								}
								cp[tagGrp.id] = valChoosen;
							}else{
								// Remove the group group from the selected variable
								if(cp[tagGrp.id])
									delete cp[tagGrp.id];
							}
							//console.log("new tab", cp);
							setTagsGroupSelection(cp)
						}}
						options={[""].concat(tagGrp.data).map((oTag) => {
							return {
								"value": oTag.name? JSON.stringify({
									"prefix": tagGrp.prefix,
									"data": oTag.name
								}) : '', 
								"label": oTag.name? oTag.name : '-'}
						})}
						value={(() => {
							if(!tagsGroupSelection[tagGrp.id]) return []
							let output = [];
							tagsGroupSelection[tagGrp.id].forEach(objStr => {
								let obj = JSON.parse(objStr)
								output.push({value: objStr , label: obj.data})
							});
							return output;
						})()}
						//defaultValue={[{value: selectedCountry? selectedCountry : "", label: selectedCountry? selectedCountry : "-"}]}
						isSearchable={true}
						isMulti={parseInt(tagGrp.multi_select)? true : false}
					/>
				</div>
			})}
		</div>
	}

	const modalDeleteItem = () => {
		if(props.updateMode && props.updateMode.id)
			props.modal({
				show: true,
				title: "Confirmation",
				html: () => {
					return <div>
						Are you sure you want to delete the following product: <span className="font-weight-bold">{props.updateMode.id}</span>
					</div>
				}
				,ok: {
					title: "I'm sure",
					fnc: (popup, close) => {
						console.log('props.updateMode', props.updateMode);

						ajaxPerso( {
							"api": `shopify`,
							"page": `products/${props.updateMode.id}.json`,
							"trigger": "request",
							"method": "DELETE",
							"shop": props.shopSelected.id,
						}, (r) => {
							if(!initialState._isMounted)	return false
							console.info('Execute delTemplate:', r);
							let info = {error: false, success: false}
							if(r.code === 200 || r.code === 201){

								ajaxPerso( {
									"api": `shopify`,
									"trigger": "history_delete",
									"uid": props.getUser().uid,
									"id_product_shopify": props.updateMode.id,
								}, (r) => {
									if(!initialState._isMounted)	return false
									console.info('Execute history_delete:', r);
								})
								
								close()
								emptyFormTotal()
								info.success = <div key={"linkInDeleteItem"}>Item: <u>{props.updateMode.title}</u> successfully removed.</div>
								props.parent.history.push("./");
							}else{
								info.error = r.reason;
							}
							props.msgAlert(info.error, info.success)
						})
					}
				},
				return: {
					title: "Close"
				}
			})
	}

	const sendSocketImgs = (base64ImgsBin, r) => {
		
		base64ImgsBin.forEach((img64) => {
			if(!img64.isFromShopify){
				let objImgToAdd = {
					"position": img64.position,
					"attachment": img64.attachment.substr(img64.attachment.indexOf("base64,")+"base64,".length),
					"filename": img64.name
				}

				socketPerso("task", {
					url: refConst.urlServer,
					// token: refConst.tokenEio,
					internalReq: {
						"api": "shopify",
						"trigger": "request",
						"shop": props.shopSelected.id,
						"page": "products/" + r.body.product.id + "/images.json",
						"method": "POST",
						"body": JSON.stringify({
							"image": objImgToAdd
						})
					},
					task: {
						op: "insert",
						type: "shopify_img",
						store: props.shopSelected.id,
						args: {
							uid: props.getUser().uid,
							name: `Insert image for ${r.body.product.title}`
						}
					}
				})

				/* promises.push(new Promise(function(resolve) {
					ajaxPerso( {
						"api": "shopify",
						"trigger": "request",
						"shop": props.shopSelected.id,
						"page": "products/" + r.body.product.id + "/images.json",
						"method": "POST",
						"body": JSON.stringify({
							"image": objImgToAdd
						}),
					}, (r) => {
						if(!initialState._isMounted)	return false
						console.info('IMG DONE:', r);
						resolve(r)
					})
				})) */
			}
		})
	}

	const sendSocketFiles = (base64FilesBin, r) => {
		/* var formData = new FormData();
		// var blob = new Blob(base64FilesBin[0], { type: "application/pdf"});
		base64FilesBin.forEach((o, i) => {
			console.info(`base64FilesBin[${i}]`, o);
			formData.append(`content[${i}]`, o);
		}) */
		
		/* formData.append("token", refConst.tokenEio);
		//formData.append("api", "azure");
		//formData.append("trigger", "insertBlobMulti");
		formData.append("api", "shopify");
		formData.append("trigger", "addFilesProcess");
		formData.append("accName", "globalresourceswebsite");
		formData.append("container", "productshopify");
		formData.append("shop", props.updateMode.id);
		formData.append("method", "POST"); */

		let productData = r.body.product;

		var urlAlreadyThere = [];
		base64FilesBin.forEach((o, i) => {
			console.info(`base64FilesBin[${i}]`, o);
			if(o.isFromShopify)
				urlAlreadyThere.push(o.url);
		})

		if(JSON.stringify(urlAlreadyThere) === JSON.stringify(props.loadedBinList) && !base64FilesBin.find(o => {if(!o.isFromShopify) return o})){
			console.info('File not require to update.');
			return false;
		}

		/* console.info('loadedBinList', props.loadedBinList);
		console.info('urlAlreadyThere', urlAlreadyThere);
		console.info('Result', JSON.stringify(urlAlreadyThere) !== JSON.stringify(props.loadedBinList)); */
		
		console.info("productData", productData);

		var formData = {
			token: refConst.tokenEio,
			api: "shopify",
			trigger: "addFilesProcess",
			accName: "globalresourceswebsite",
			container: "productshopify",
			shop: props.shopSelected.id,
			sku: productData.variants[0].sku,
			method: "POST",
			filesBin: [],
			createFor: [props.shopSelected.id].concat(props.alsoCreateFilesFor).join(','),
			urlAlreadyThere: urlAlreadyThere.join(','),
			orderFileName: base64FilesBin.map((o, i) => {
				return o.name;
			}).join(',')
		};

		let newIndex = 0;
		base64FilesBin.forEach((o, i) => {
			console.info(`base64FilesBin[${i}]`, o);
			if(!o.isFromShopify){
				formData.filesBin.push({
					key: "files["+(newIndex++)+"]",
					bin: o,
					fileName: o.name,
					fileType: o.type
				});
			}
		})

		console.info('formData', formData);
		
		socketPerso("task", {
			url: refConst.urlServer,
			// token: refConst.tokenEio,
			formData: formData,
			task: {
				op: "insert",
				type: "shopify_file",
				store: props.shopSelected.id,
				args: {
					uid: props.getUser().uid,
					name: `Insert file for ${productData.title}`
				}
			}
		})

		// formData.content = formData.content[0]
		/* $.ajax({
			"url": refConst.urlServer,
			"type": "POST",
			"cache": false,
			processData: false,
			contentType: false,
			"data": formData,
		}, (riPost) => {
			console.info('infoooo:', riPost);
		}, "json"); */
	}

	const promo_gestion = (originalPrice, price, idProduct, idVariant) => {
		// We need to save the promo.
		if(promoMetaObj){
			try {
				/**
				 * Add this promo will have for effect to end the prev one and add a new one, we can avoid this if we just do a simple update
				 */
				console.info(`SEE PRICE: ${originalPrice} !== ${price}`);
				console.info(`${JSON.stringify(promoMetaObjOriginal)} !== ${JSON.stringify(promoMetaObj)}`);


				/* console.info('JSON SEND', shopify_promo_object(
					props.shopSelected.id,
					idProduct,
					idVariant,
					price,
					promoMetaObj
				));
				
				return; */
				
				if(!metaPromoExist || originalPrice !== price || JSON.stringify(promoMetaObjOriginal) !== JSON.stringify(promoMetaObj))
					ajaxPerso( {
						"api": "shopify",
						"trigger": "add_promo",
						"method": "POST",
						"json": JSON.stringify(
							shopify_promo_object(
								props.shopSelected.id,
								idProduct,
								idVariant,
								price,
								promoMetaObj
							)
						)
					}, (resPromo) => {
						console.info("Triggered 'add_promo':", resPromo);
						if(resPromo.success){
							setPromoMetaObjOriginal(promoMetaObj)
							props.info({success: "Promo Saved."})
						}else{
							props.info({error: resPromo.errors})
						}
					})
			} catch (error) {
				props.info({"error": error})
			}
		}else if(metaPromoExist){
			//* If the promo object was deleted and that a meta existed, we need to end it.
			ajaxPerso( {
				"api": "shopify",
				"shop": props.shopSelected.id,
				"trigger": "end_promo",
				"method": "POST",
				"id_variant_shopify": idVariant,
			}, (resPromo) => {
				console.info("Triggered 'end_promo':", resPromo);
				if(resPromo.success)
					props.info({"success": "Promo Removed.", "error": resPromo.error})
			})
		}
	}

	const searchExistingPrtoduct = (skuValue) => {
		if(skuValue && selectedBrand){
			fetchItemFromFinale(skuValue, (r) => {
				if(!initialState._isMounted)	return false
				if(r && r.length > 0){
					setItemFinale(r[0])
				}
				console.warn("getItems Finale", r);
			})
			fetchItemFromShopify(skuValue, (r) => {
				if(!initialState._isMounted)	return false
				if(r.success && r.data.length > 0){
					// Load the item:
					props.parent.history.push("/shopify/" + r.data[0].id_product_shopify)
				}
				console.warn("getItems Shopify", r);
			})
		}
	}

	const removePrefix = (sku) => {
		if(sku.indexOf(selectedBrand.prefix) !== -1){
			return sku.substr(selectedBrand.prefix.length)
		}
		return sku
	}

	console.info("needSkuPrefix", needSkuPrefix);
	
	return (
		<div>
			<div className="form-group d-flex flex-row">
				<div>
					<div className="custom-control custom-switch mb-3 mr-3 w-100 mr-2">
						<input type="checkbox" className="custom-control-input" id="customSwitchPublished" checked={published === 1? true : false} onChange={(e) => { setPublished(e.target.checked? 1 : 0) }}/>
						<label className="custom-control-label noselect pointer" htmlFor="customSwitchPublished">Published</label>
					</div>
					{/* {
						<ModalSpecialPromo
							{...props}
							className={"custom-control custom-switch mb-3 mr-3 w-100"}
							title={props.updateMode? props.updateMode.title : ""}
							titleUi={"Promo Tool"}
							style={"radio"}
							promoPageListItems={promoPageListItems}
							setPromoPageListItems={setPromoPageListItems}
							promoMetaObj={promoMetaObj}
							setPromoMetaObj={setPromoMetaObj}
							metaPromoPageName={metaPromoPageName}
							promo_gestion={promo_gestion}
							price={price}
							originalPrice={originalPrice}
						/>
					} */}
					{
						props.shopSelected && props.shopSelected.id == refConst.id_store_eio? <div className="custom-control custom-switch mb-3 w-100 mr-2">
							<input type="checkbox" className="custom-control-input mr-2" id="customSwitchRated" checked={rated.length > 0? true : false} onChange={(e) => { triggerShippingRateChange(e.target.checked) }}/>
							<label className="custom-control-label noselect pointer mr-2" htmlFor="customSwitchRated" data-toggle="tooltip" title="Check it to remove Free Shipping">Rated Shipping</label>
						</div> : ""
					}
				</div>
				<div>
					<div className="custom-control custom-switch mb-3 w-100 mr-2">
						<input type="checkbox" className="custom-control-input mr-2" id="customSwitchHideA" checked={hideAplus} onChange={(e) => { setHideAplus(e.target.checked) }}/>
						<label className="custom-control-label noselect pointer mr-2" htmlFor="customSwitchHideA" data-toggle="tooltip" title="Check only if asked">Hide A+ section</label>
					</div>
				</div>
				<div>
					{
						props.securityClearance(props, [
							"-MjVxK86GVBUKL5oNU66", // admin_access
							"-MjVTXBQweEPcKSCGTUH", // shopify_haus_delete
							"-MjVTVOvaHGqeXLg0bqj", // shopify_eio_delete
						]) && props.updateMode && props.updateMode.id? <button type="button" className="btn btn-danger" onClick={modalDeleteItem}>Remove</button> : ""
					}
				</div>
			</div>

			{formSelectionBrand()}
			{!props.isAutoCreated? sectionVariant() : ""}
			{!props.isAutoCreated? formSelectVariant() : ""}
			{formSelectionCollection()}
			{htmlTagsGroup()}
			
			{props.loadAndshowForm(props.shopSelected.name, {
				...props,
				getBase64: getBase64,
				sendSocketImgs: sendSocketImgs,
				sendSocketFiles: sendSocketFiles,
				selectedMenu: selectedMenu,
				selectedBrand: selectedBrand,
				setSelectedBrand: setSelectedBrand,
				setSelectedMenu: setSelectedMenu,
				setSelectedCountry: setSelectedCountry,
				formSelectionCountry: formSelectionCountry,
				selectedCountry: selectedCountry,
				menu: props.menu,
				updateMeta: updateMeta,
				initialState: Object.assign(initialState, props.initialState),
				submitForm: submitForm,
				formDefineVideoInfo: formDefineVideoInfo,
				linkVideo: linkVideo,
				setLinkVideo: setLinkVideo,
				nbrVideoMax: nbrVideoMax,
				htmlDescVideo: htmlDescVideo,
				brandObject: brandObject,
				setBrandObject: setBrandObject,
				tagsGroup: tagsGroup,
				setTagsGroup: setTagsGroup,
				tagsGroupSelection: tagsGroupSelection,
				setTagsGroupSelection: setTagsGroupSelection,
				fetchItemFromFinale: fetchItemFromFinale,
				fetchItemFromShopify: fetchItemFromShopify,
				itemFinale: itemFinale,
				setItemFinale: setItemFinale,
				published: published,
				setPublished: setPublished,
				hideAplus: hideAplus,
				setHideAplus: setHideAplus,
				isDraft: isDraft,
				setIsDraft: setIsDraft,

				promoPageListItems: promoPageListItems,
				setPromoPageListItems: setPromoPageListItems,

				promoMetaObj: promoMetaObj,
				setPromoMetaObj: setPromoMetaObj,
				setPromoMetaObjOriginal: setPromoMetaObjOriginal,

				metaPromoExist: metaPromoExist,
				setMetaPromoExist: setMetaPromoExist,

				promo_gestion: promo_gestion,

				originalPrice: originalPrice,
				setOriginalPrice: setOriginalPrice,
				compareAtPrice: compareAtPrice,
				setCompareAtPrice: setCompareAtPrice,
				price: price,
				setPrice: setPrice,

				showVariants: showVariants,
				showAddingOptionsSection: showAddingOptionsSection,
				addOptionToList: addOptionToList,
				sectionVariant: sectionVariant,
				searchExistingPrtoduct: searchExistingPrtoduct,
				fillVariantSpecifics: fillVariantSpecifics,
				needSkuPrefix: needSkuPrefix,
				setNeedSkuPrefix: setNeedSkuPrefix,
				removePrefixFromSku: removePrefixFromSku,
				otherTags: otherTags,
				setOtherTags: setOtherTags,
				inventoryPolicy: inventoryPolicy,
				setInventoryPolicy: setInventoryPolicy,
				bulletsArea: bulletsArea,
				setBulletsArea: setBulletsArea,
				extendedBody: extendedBody,
				setExtendedBody: setExtendedBody,
				shortDesc: shortDesc,
				setShortDesc: setShortDesc,
				emptyFormTotal: emptyFormTotal,
				removePrefix: removePrefix,
			})}
				
		</div>);
}

export default FormShopifyListing