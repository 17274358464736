// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery';
import wiziwig from '../../../ctn/wiziwig'
import logoStoreEio from '../../../../img/fav-trans-eio.png';
import {
	NavLink
} from 'react-router-dom';
import {ajaxPerso, socketPerso, hasOnlyDefaultVariant, confirmation_modal, uniqueArray, historyInsert, shopifyRefreshData} from "../../../../fnc.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
const refConst = require("../../../../constants.js").v

var initialState = {
	// mpn: "",
	origin: "",
	desc: "",
	descVideo: "",
	freeShipping: 1,
	promoMetaObj: null,
	_isMounted: false
}

function EioForm(props) {

	// const [mpn, setMpn] = useState(initialState.mpn);
	//const [desc, setDesc] = useState(initialState.desc);
	//const [descVideo, setDescVideo] = useState(initialState.descVideo);
	const [freeShipping, setFreeShipping] = useState(initialState.freeShipping);

	useEffect(() => {
		initialState._isMounted = true;
		return () => initialState._isMounted = false
	}, [])

	useEffect(() => {

		console.log("updateMode", props.updateMode);

		let item = props.updateMode

		props.setProposalHidden(true)

		if(item){

			if(props.variantsSpecificsToSubmit.length > 0 && props.selectedVariant !== null){

				let variantBasicStructure = props.fillVariantSpecifics()
				props.setVariantMode(!hasOnlyDefaultVariant(item.options))

				props.setMsgInfo(props.initialState)
				props.setPublished(item.published_at? 1 : 0)
				props.setTitle(item.title)
				let objBrand = props.brands.find(obj => {
					if(obj.name === item.vendor)		return obj
					return false;
				})
				props.setSelectedBrand(objBrand || {})

				let binListConstruct = [];

				binListConstruct = binListConstruct.concat(item.images.map((obj, index) => {
					obj.isFromShopify = true
					obj.pos = index
					obj.typeBin = "img"
					return obj
				}))

				/**
				 * 	We need to separate all our tools tags from tgas that weren't created from this tool
				 *  - When we submit a product/update, we create all the necessary tags and add all those captured in otherTags
				 */
				let otherTagsTmp = [];
				let tags = item.tags.split(',');
				let objSelectedTags = Object.assign({}, props.initialState.tagsGroupSelection);
				let catSelected = null
				tags.forEach(tag => {
					let matchFound = true;
					if(tag.trim().indexOf("Origin_") >= 0){
						props.setSelectedCountry(tag.trim().substr("Origin_".length))
					}else if(tag.trim().indexOf("Category_") >= 0){
						catSelected = tag.trim().substr("Category_".length)
						props.setSelectedMenu(tag.trim().substr("Category_".length))
					}else if(tag.trim().indexOf("hideAPS") >= 0){
						props.setHideAplus(true)
					}else if(tag.trim().indexOf("draft") >= 0){
						props.setIsDraft(true)
					}else if(tag.trim().indexOf("Brand_") >= 0 || tag.trim().indexOf("Free Shipping") >= 0){
						// We do not have to do anything from these tags, they will be filled by the vendor/free shipping checkbox
						// Free shipping is auto filled from our free shipping metafield.
					}else{
						matchFound = false;
					}

					let tagGrpFound = false;
					props.tagsGroup.forEach(objTagsGrp => {
						if(tag.trim().indexOf(objTagsGrp.prefix) >= 0){
							tagGrpFound = true
							if(!objSelectedTags[objTagsGrp.id])
								objSelectedTags[objTagsGrp.id] = [];

							objSelectedTags[objTagsGrp.id].push(JSON.stringify({
								prefix: objTagsGrp.prefix,
								data: tag.trim().substr(objTagsGrp.prefix.length)
							}));
						}
					})

					if(!tagGrpFound && !matchFound)	otherTagsTmp.push(tag.trim())
				})

				// Since we possibly found our category, we now need to remove the tga that has the category name from our otherTags list, 
				// this tag will be added in the submit fase.
				if(catSelected)
					tags.forEach(tag => {
						if(tag.trim().indexOf(catSelected) >= 0){

							otherTagsTmp.forEach((t, pos) => {
								if(t.trim().indexOf(catSelected) >= 0){
									otherTagsTmp.splice(pos, 1)
									return;
								}
							})
						}
					})

				props.setOtherTags(otherTagsTmp)
				props.setTagsGroupSelection(objSelectedTags)

				let videoDescTmp = null,
					recoursesTmp = null,
					additionalFiles = null,
					bulletAreaTmp = null,
					promoMetaFound = false

				item.metafields.forEach(obj => {
					if(obj.key === "mpn"){
						// let mpnList = {}
						if(obj.type.indexOf("json") !== -1){
							// mpnList = JSON.parse(obj.value)
							let currentListMpn = JSON.parse(obj.value)
							console.info("2currentListMpn", currentListMpn);
							for (const posIn in currentListMpn) {
								if (Object.hasOwnProperty.call(currentListMpn, posIn)) {
									variantBasicStructure[posIn].valFilled.mpn = currentListMpn[posIn]
								}
							}
							// console.info("mpn json", mpnList);
						}else if(variantBasicStructure.length > 0){
							console.info(`add mpn to variant ${Object.keys(variantBasicStructure)[0]}`, obj.value);
							// mpnList[Object.keys(props.variantsSpecificsToSubmit)[0]] = obj.value // key start from 1
							variantBasicStructure[Object.keys(variantBasicStructure)[0]].valFilled.mpn = obj.value // key start from 1
							// props.variantsSpecificsToSubmit[Object.keys(props.variantsSpecificsToSubmit)[0]].valFilled.mpn = obj.value
						}
						props.setRefreshView(props.refreshView+1)
					}else if(obj.key === "short_description" && obj.value)
						$('#inputSortDesc').summernote("code", obj.value)
					else if(obj.key === "free_shipping")
						setFreeShipping(parseInt(obj.value))
					else if(obj.key === "extended_body")
						props.setExtendedBody(obj.value)
					else if(obj.key === "recourses") recoursesTmp = obj
					else if(obj.key === "additional_files") additionalFiles = obj
					else if(obj.key === "bullet_points") bulletAreaTmp = obj
					else if(obj.key === "video_description") videoDescTmp = obj
					else if(obj.key === "promo"){
						promoMetaFound = true
						props.setPromoMetaObj(JSON.parse(obj.value))
						props.setPromoMetaObjOriginal(JSON.parse(obj.value))
					}
				})

				item.variants.forEach((v) => {
					if(variantBasicStructure[v.position].valFilled.mpn.length === 0 && v.sku.length > 0){
						let skuCleaned = v.sku
						if(skuCleaned.indexOf(props.selectedBrand.prefix) !== -1){
							skuCleaned = skuCleaned.substr(props.selectedBrand.prefix.length)
						}
						variantBasicStructure[v.position].valFilled.mpn = skuCleaned
						console.info("added sku:", skuCleaned)
					}
				})

				props.setVariantsSpecificsToSubmit(variantBasicStructure)
				props.setSelectedVariant(variantBasicStructure.length > 0? variantBasicStructure[1].valFilled.position : 1)

				if(!promoMetaFound){
					props.setPromoMetaObjOriginal(null)
					props.setPromoMetaObj(null)
					props.setMetaPromoExist(false);
				}else{
					props.setMetaPromoExist(true);
				}

				if(bulletAreaTmp){
					try {
						let objBullet = JSON.parse(bulletAreaTmp.value)
						props.setBulletsArea(objBullet.data.join("\n"))
					} catch (error) {
						console.warn(error.message);
					}
				}


				try {
					if(recoursesTmp){
						let files = JSON.parse(recoursesTmp.value)
						binListConstruct = binListConstruct.concat(files.map((url, i) => {
							return {
								typeBin: "file",
								isFromShopify: true,
								url: url,
								pos: i,
								name: url.split('/').pop()
							}
						}))
						props.setLoadedBinList(files)
					}

					if(additionalFiles){
						let filesFile = JSON.parse(additionalFiles.value)
						console.info("filesFile", filesFile);
						let fileDataList = filesFile.map((o, i) => {
							o.pos  = i
							return o
						})
						props.setFileShopify(fileDataList.slice(0))
					}
				} catch (error) {
					console.warn(error.message);
				}
				console.info('binListConstruct', binListConstruct);
				props.setBinList(binListConstruct)

				let linksVideo = [];
				if(videoDescTmp){
					try {
						let objVideo = JSON.parse(videoDescTmp.value)

						if(Object.keys(objVideo).indexOf("data") >= 0)
							objVideo = objVideo.data

						if(objVideo.length > 0)
							objVideo.forEach((obj, index) => {
								$('#inputDescVideo_' + index).summernote("code", obj.description);
								linksVideo.push(obj.link)
							})
						else
							for (let i = 0; i < props.nbrVideoMax; i++){
								if(!$("#inputDescVideo_" + i).summernote('isEmpty'))
									$("#inputDescVideo_" + i).summernote("code", "");
							}
					} catch (error) {
						console.warn(error.message);
					}
				}else{
					for (let i = 0; i < props.nbrVideoMax; i++){
						if(!$("#inputDescVideo_" + i).summernote('isEmpty'))
							$("#inputDescVideo_" + i).summernote("code", "");
					}
				}
				props.setLinkVideo(linksVideo)
			}
		}else if(item === null){ // for when we click on Go to empty form
			props.emptyFormTotal(() => {
				setFreeShipping(initialState.freeShipping)
			})
		}
		
	}, [props.updateMode])

	useEffect(() => {
		$('.tooltipBullet').tooltip({
			placement: "right",
			title: "Press Enter after each line to create a new bullet line once saved (limited to 5)"
		}).tooltip('update')
	})

	useEffect(() => {
		//wiziwig("#inputDesc", "Write the description here")
		wiziwig("#inputSortDesc", "Write the short description here")
		//wiziwig("#inputDescVideo", "Write the video_description here")
		wiziwig(".descVideo", "Write the video description here")

		props.setVersion("3.0.0")
	}, [])

	useEffect(() => {
		if(props.itemFinale){
			if(props.itemFinale.upc || props.itemFinale.ean){
				let finalebarcode = props.itemFinale.upc? props.itemFinale.upc : props.itemFinale.ean
				props.variantsSpecificsToSubmit.forEach(vs => {
					if(vs.valFilled.sku == props.itemFinale.sku){
						vs.valFilled.barcode = finalebarcode
						props.setRefreshView(props.refreshView+1)
					}
				})
			}
		}
	}, [props.itemFinale])

	const createTags = (startingTags) => {
		var tags = Array.isArray(startingTags) && startingTags.length > 0? startingTags : [];

		if(props.selectedBrand)
			tags.push( "Brand_" + props.selectedBrand.name )

		if(props.selectedMenu){
			tags.push( "Category_" + props.selectedMenu )
			tags.push( props.selectedMenu )
		}

		if(props.selectedCountry)
			tags.push( "Origin_" + props.selectedCountry )

		if(freeShipping)
			tags.push( "Free Shipping" )

		if(props.hideAplus)
			tags.push( "hideAPS" )

		// The only way to remove the draft tag is through the "Shopify Draft tool"
		if(props.isDraft)
			tags.push( "draft" )

		//console.log("props.tagsGroupSelection", props.tagsGroupSelection);	
		if(props.tagsGroupSelection){
			for (const idGrp in props.tagsGroupSelection) {
				if (props.tagsGroupSelection.hasOwnProperty(idGrp)) {
					const list = props.tagsGroupSelection[idGrp]
					list.forEach(objStr => {
						const obj = JSON.parse(objStr)
						tags.push(obj.prefix + obj.data.replace(",", "-"))
					})
				}
			}
		}

		tags = tags.concat(props.otherTags)

		// We make sure to remove all commas (since this symbol is the delimiter on Shopify)
		tags.forEach((line, index) => {
			tags[index] = tags[index].replace(/,/g, "")
		})

		return tags.join(",")
	}

	const createMetas = () => {

		/**
		 * If we try to update a metafield type, we might have to recreate a new metafield with the same key to overwrite the older one.
		 * Shopify mightnot be able to move from single_line_text_field to json for example.
		 */

		var metas = [];
		let shortDescription = !$("#inputSortDesc").summernote('isEmpty')? $("#inputSortDesc").val().replace(/™|©/g,'') : '';
		let tabVideoObj = props.htmlDescVideo()
		let tabBulletObj = bulletsAreaObject()
		// let objPromo = props.promoMetaObj

		console.info('tabBulletObj', tabBulletObj);
		
		let item = props.updateMode,
			promises = [],
			metasTakingCareOf = [],
			mpnList = {}

		let filteredVariants = JSON.parse(JSON.stringify(variantFilterValidData()))

		filteredVariants.forEach((o) => {
			if(o.valFilled.mpn)
				mpnList[o.valFilled.position] = o.valFilled.mpn
		})

		/**
		 * In case of an update:
		 * 	-	We update each individual meta.
		 *  In case of a new item creation:
		 * 	-	We return the list of metafield to add the shopify object.
		 */
		if(item){
			item.metafields.forEach(obj => {
				if(["mpn", "short_description", "free_shipping", "extended_body", "video_description", "bullet_points", "additional_files"].indexOf(obj.key) >= 0){
					metasTakingCareOf.push(obj.key)
					promises.push( new Promise(function(resolve, reject) {
						let _updated = false

						if(obj.key === "short_description")		{ _updated = obj.value != shortDescription; obj.value = shortDescription }
						if(obj.key === "mpn"){
							/**
							 * We overwrite the metafield if the type need to be changed (shopify may not be able to do the change depending of the type),
							 * We delete it if no value exist as mpn
							 */
							let wantedValue = Object.keys(mpnList).length > 0? (props.variantMode? JSON.stringify(mpnList) : mpnList[(Object.keys(mpnList))[0]]) : ""
							let wantedType = Object.keys(mpnList).length > 0 && props.variantMode? "json" : "single_line_text_field"

							if(Object.keys(mpnList).length === 0) // || obj.type != newWantedType
								obj.hasToBeDeleted = true
							else if(obj.value != wantedValue) //  && obj.type == wantedType
								_updated = true

							obj.value = wantedValue
							obj.type = wantedType

							console.info("Metafield value:", obj.value);
						}
						if(obj.key === "free_shipping")			{ _updated = obj.value != freeShipping.toString(); obj.value = freeShipping? '1' : '0' }
						if(obj.key === "extended_body")			{ _updated = obj.value != props.extendedBody; obj.value = props.extendedBody.replace(/™|©/g,'') }
						if(obj.key === "video_description"){
							if(tabVideoObj.data.length === 0)
								obj.hasToBeDeleted = true
							else
								_updated = obj.value != JSON.stringify(tabVideoObj)
							obj.value = JSON.stringify(tabVideoObj); obj.type = "json";
						}
						if(obj.key === "bullet_points"){
							if(tabBulletObj.data.length === 0)
								obj.hasToBeDeleted = true
							else
								_updated = obj.value != JSON.stringify(tabBulletObj)
							obj.value = JSON.stringify(tabBulletObj); obj.type = "json";
						}
						if(obj.key === "additional_files"){
							if(props.fileShopify.length === 0)
								obj.hasToBeDeleted = true
							else
								_updated = obj.value != JSON.stringify(props.fileShopify)
							obj.value = JSON.stringify(props.fileShopify); obj.type = "json";
						}
						// if(obj.key === "promo"){	obj.value = objPromo? JSON.stringify(objPromo) : null; obj.type = "json_string";	}

						if(_updated || "hasToBeDeleted" in obj){
							props.updateMeta(item.id, obj, (r) => {
								resolve(r)
							})
						}else resolve("Nothing needed to do.")
					}))
				}
			})

			// Execute all PUT requests
			Promise.all(promises).then((r) => {
				console.info("Metafields updated:", r);
			})

			// (NO NEED, SHOPIFY DO NOT OVERWRITE THE WHOLE METAFIELDS DATA) We make sure to include every already present meta, others app also add meta to our items so we need to add them to avoid their deletion.
			// metas = item.metafields;
		}

		// "Brand_" + selectedBrand + ", Category_" + selectedMenu + ", Origin_" + selectedCountry + ", "+selectedMenu
		if(metasTakingCareOf.indexOf("extended_body") === -1 && Object.keys(props.extendedBody).length > 0)
			metas.push( {
				"key": "extended_body",
				"value": props.extendedBody.replace(/™|©/g,''),
				"type": "multi_line_text_field",
				"namespace": "inventory"
			} )
			
		if(metasTakingCareOf.indexOf("short_description") === -1 && shortDescription.length > 0)
			metas.push( {
				"key": "short_description",
				"value": shortDescription,
				"type": "multi_line_text_field",
				"namespace": "inventory"
			} )

		if(metasTakingCareOf.indexOf("free_shipping") === -1)
			metas.push( {
				"key": "free_shipping",
				"value": freeShipping? '1' : '0',
				"type": "number_integer",
				"namespace": "inventory"
			} )

		if(metasTakingCareOf.indexOf("mpn") === -1 && Object.keys(mpnList).length > 0) //  && mpnCurrentValue != JSON.stringify(mpnList)
			metas.push( {
				"key": "mpn",
				"value": Object.keys(mpnList).length > 0? (props.variantMode? JSON.stringify(mpnList) : mpnList[(Object.keys(mpnList))[0]]) : "",
				"type": Object.keys(mpnList).length > 0 && props.variantMode? "json" : "single_line_text_field",
				"namespace": "inventory"
			} )

		if(metasTakingCareOf.indexOf("video_description") === -1 && tabVideoObj.data.length > 0)
			metas.push( {
				"key": "video_description",
				"value": JSON.stringify(tabVideoObj),
				"type": "json",
				"namespace": "inventory"
			} )

		if(metasTakingCareOf.indexOf("bullet_points") === -1 && tabBulletObj.data.length > 0)
			metas.push( {
				"key": "bullet_points",
				"value": JSON.stringify(tabBulletObj),
				"type": "json",
				"namespace": "inventory"
			} )

		/* if(metasTakingCareOf.indexOf("promo") === -1 && objPromo && Object.keys(objPromo).length > 0)
			metas.push( {
				"key": "promo",
				"value": JSON.stringify(objPromo),
				"type": "json",
				"namespace": "inventory"
			} ) */
		
		return metas
	}

	const sendRequest = (base64ImgsBin, base64FilesBin) => {

		/***
		 * Will update the selected variant only.
		 */
		console.info("needSkuPrefixneedSkuPrefix", props.needSkuPrefix);
		console.log("base64ImgsBin", base64ImgsBin);
		console.log("base64FilesBin", base64FilesBin);

		console.log("globalOptions", props.globalOptions);
		console.log("navGrpOptionsSelected", props.navGrpOptionsSelected);
		console.log("variantMode", props.variantMode);
		console.log("productOptions", props.productOptions);
		console.log("variantsSpecificsToSubmit", props.variantsSpecificsToSubmit);

		props.variantsSpecificsToSubmit[props.selectedVariant].modified = true
		let filteredVariants = JSON.parse(JSON.stringify(variantFilterValidData()))

		console.info("variantsSpecificsToSubmit2", filteredVariants);

		let objRequest = {};
		let variantsToSubmit = filteredVariants.map(o => {
			if(o && "valFilled" in o)
				return o.valFilled;
		})
		variantsToSubmit.forEach(variantObj => {

			variantObj = Object.assign( variantObj, {
				// "barcode": upc,
				// "price": price,
				// "sku": (props.selectedBrand.prefix? props.selectedBrand.prefix : "") + sku,
				"inventory_policy": props.inventoryPolicy,
				"inventory_management": "shopify",
				// "inventory_quantity": qty,
				// "weight":  parseFloat(lbs? lbs : oz).toFixed(4),
				// "weight_unit": lbs? "lb" : "oz", // g, kg, oz, lb
			})

			// check if a prefix is required, but we do it only if needSkuPrefix is TRUE
			if(props.needSkuPrefix && props.selectedBrand.prefix && variantObj.sku.indexOf(props.selectedBrand.prefix) === -1)
				variantObj.sku = (props.selectedBrand.prefix? props.selectedBrand.prefix : "") + variantObj.sku;

			if(props.originalPrice !== variantObj.price){
				variantObj.originalPrice = variantObj.price
			}
		})

		let startingTags = [];
		if(props.updateMode){

			objRequest.id = props.updateMode.id

		}else{
			// Restrict some people to draft only when creating new products
			/* if(props.getUser().email.substr(0,3) === "de_"){
				objRequest.status = "draft"
				startingTags.push("draft")
			} */
		}

		/* console.info('otherTagsotherTags', props.otherTags);
		console.info('createTags', createTags());
		return; */

		if(!props.isAutoCreated){
			objRequest.options = props.productOptions;
			objRequest.variants = variantsToSubmit.map(v => {
				// We need to remove everything concerning the Inventory cause shopify is making it's own api for it.
				let v2 = Object.assign({}, v)
				delete v2.inventory_quantity
				delete v2.inventory_quantity_adjustment
				delete v2.old_inventory_quantity
				delete v2.inventory_item_id
				return v2
			})
		}

		objRequest = Object.assign(objRequest, {
			"title": props.title.replace(/™|©/g,''),
			"body_html": props.htmlDT,
			"vendor": props.selectedBrand.name,
			"product_type": props.selectedMenu,
			"tags": createTags(startingTags),
			"published": props.published,
			"published_scope": "global",
			"metafields_global_description_tag": "Buy and save on " + props.title + " with fast shipping and unbeatable prices, you're good to go with EIO.com.",
			"images": base64ImgsBin.map((img64) => {
				if(img64.isFromShopify){
					return {
						"id": img64.id,
						"position": img64.position,
						"src": img64.src
					}
				}
			})
		});


		let meta = createMetas();
		if(meta.length > 0)
			objRequest.metafields = meta

		console.log("meta", meta);
		console.log("objRequest", objRequest);
		// return false;

		if(props.updateMode)
			objRequest.id = props.updateMode.id

		ajaxPerso({
			"api": "shopify",
			"trigger": "request",
			"shop": props.shopSelected.id,
			"page": "products" + (props.updateMode? "/" + props.updateMode.id : "") + ".json",
			"method": props.updateMode? "PUT" : "POST",
			"body": JSON.stringify({
				"product": objRequest
			}),
		}, function(r) {
			console.info('initialState._isMounted', initialState._isMounted);
			if(!initialState._isMounted)	return false

			let info = { success: [], error: []}

			if(r.code === 201 || r.code === 200){

				r.body.product.variants.forEach(variant => {

					let varToUpdate = variantsToSubmit.find(vM => {
						if(vM.sku == variant.sku && parseInt(vM.inventory_quantity) != parseInt(variant.inventory_quantity))
							return vM
					})

					console.info("varToUpdate", varToUpdate);

					azureShippingRate(r.body.product.id, variant.id, freeShipping)
	
					historyInsert(props.shopSelected.id, {
						"uid": props.getUser().uid,
						"id_product": r.body.product.id,
						"sku_shopify": variant.sku,
						"type": r.code === 201? "insert" : "update",
					}, (r) => {
						console.info("historyInsert", r);
						props.loadUserHistory();
					})

					if(varToUpdate){

						ajaxPerso( {
							"api": "shopify",
							"trigger": "request",
							"shop": props.shopSelected.id,
							"page": `inventory_levels.json?inventory_item_ids=${variant.inventory_item_id}`,
							"method": "GET"
						}, (riGet) => {
							console.info('inventory_levels:', riGet.body.inventory_levels[0]);
							if(riGet.success && riGet.body.inventory_levels.length > 0){
								let inventory = riGet.body.inventory_levels[0]
	
								ajaxPerso( {
									"api": "shopify",
									"trigger": "request",
									"shop": props.shopSelected.id,
									"page": "inventory_levels/set.json",
									"method": "POST",
									"body": JSON.stringify({
										"location_id": inventory.location_id,
										"inventory_item_id": variant.inventory_item_id,
										"available": varToUpdate.inventory_quantity
									}),
								}, (riPost) => {
									console.info(`Request inventory_levels for ${variant.sku}:`, riPost);
									if(riPost.success)
										info.success.push('Inventory Updated.')
									else
										info.error.push('Error when trying to update the inventory.')
									
									props.msgAlert(info.error, info.success)
									shopifyRefreshData(props.shopSelected.id, null, [r.body.product.id])
								}); 
							}else{
								info.error = "No inventory quantity found.";
							}
						});
					}else{
						console.info('Same inventory quantity.');
						shopifyRefreshData(props.shopSelected.id, null, [r.body.product.id])
					}
				});

				props.sendSocketImgs(base64ImgsBin, r)
				props.sendSocketFiles(base64FilesBin, r)

				/* Promise.all(promises).then((resPromise) => {
					props.loading(false);
					console.info("IMG RESULT:", resPromise);
					props.emptyFormTotal();
					info.success = [<div key={"linkOut"+r.body.product.id}>Item [<a target="_blank" rel="noopener noreferrer" href={"https://" + props.shopSelected.shopName + ".myshopify.com/products/" + r.body.product.handle}>{ r.body.product.id}</a>] {(props.updateMode? "updated" : "added")} with success.</div>];
					info.success.push(<div key={"linkIn"+r.body.product.id}>Open again <NavLink to={"/shopify/"+r.body.product.id}>{r.body.product.id}</NavLink></div>);
					props.parent.history.push("/shopify")
					props.msgAlert(info.error, info.success)
				}) */

				props.loading(false);
				props.emptyFormTotal(() => {
					setFreeShipping(initialState.freeShipping)
				});
				info.success.push(<div key={"linkOut"+r.body.product.id}>Item [<a target="_blank" rel="noopener noreferrer" href={"https://" + props.shopSelected.account_name + "/products/" + r.body.product.handle}>{ r.body.product.id}</a>] {(props.updateMode? "updated" : "added")} with success.</div>);
				info.success.push(<div key={"linkIn"+r.body.product.id}>Open again <NavLink to={"/shopify/"+r.body.product.id}>{r.body.product.id}</NavLink>.</div>);
				props.parent.history.push("/shopify")

				socketPerso("askForWinningList", {
					"url": refConst.urlServer,
					// "token": refConst.tokenEio
				})

			}else{
				try {
					info.error = Object.keys(r.body.errors).map((key, i) => {
						return key + ": " + r.body.errors[key]
					});
				} catch (e) {
					console.warn(e.message);
					info.error = r.code + ": " + r.reason;
				}
			}

			props.msgAlert(info.error.length > 0? info.error : null, info.success.length > 0? info.success : null)
			console.log(r);

			// Load the new item if in update mode
			//props.loadItem(props.updateMode.id)
		}, function(jqXHR, textStatus) {
			props.loading(false);

			console.warn( textStatus );
			props.info({ error: textStatus });
		});
	}

	function controlValue(callback){

		let passed = true,
			msg = {},
			listWithNoError = []

		let filteredVariant = variantFilterValidData()
		console.info("control_filteredVariant", filteredVariant);

		/**
		 * We need to give a warning if some variant were not set, the main product option will reflect only the created and fully filled variants.
		 * The price is the only value require to create a variant.
		 */

		let variantCountWithError = [],
			warningRequiredForUnfilledVariantPosList = [],
			variantMultiErrors = []

		console.info("props.variantsSpecificsToSubmit.", props.variantsSpecificsToSubmit);
		props.variantsSpecificsToSubmit.forEach((vS, pos) => {

			let hasError = false

			// This part if just for our variants filling warning
			if(!vS.valFilled.price || vS.valFilled.price == ""){
				warningRequiredForUnfilledVariantPosList.push(pos)
			}

			// if(vS.modified){

				if(!vS.valFilled.sku || vS.valFilled.sku == ""){
					passed = false;
					msg[`sku${pos}`] = `[variant:${pos}] Please fill the sku`
					variantMultiErrors.push(pos)
					hasError = true
				}

				if(!vS.valFilled.mpn || vS.valFilled.mpn == ""){
					passed = false;
					msg[`mpn${pos}`] = `[variant:${pos}] Please fill the MPN`
					variantMultiErrors.push(pos)
					hasError = true
				}
		
				if(!vS.valFilled.price || vS.valFilled.price == "" || parseFloat(vS.valFilled.price) < 1.0){
	
					passed = false;
					msg[`price${pos}`] = `[variant:${pos}] Please indicate a price greater than $1`
					variantMultiErrors.push(pos)
					hasError = true
	
				}else if(props.itemFinale.cost && parseFloat(props.itemFinale.cost) >= parseFloat(vS.valFilled.price)){
	
					passed = false;
					msg[`price${pos}`] = `[variant:${pos}] Please indicate a price greater than item cost ($${parseFloat(props.itemFinale.cost).toFixed(2)})`
					variantMultiErrors.push(pos)
					hasError = true
				}
	
				if(!vS.valFilled.weight || vS.valFilled.weight == "" || Number.isNaN(vS.valFilled.weight)){
					passed = false;
					msg[`weight${pos}`] = `[variant:${pos}] Please fill the weight (Number format)`
					variantMultiErrors.push(pos)
					hasError = true
				}

				if(!hasError){
					listWithNoError.push(vS)
				}
		
				if(!passed){
					variantCountWithError.push(pos)
					warningRequiredForUnfilledVariantPosList.push(pos)
				}
			// }
		})

		if(variantMultiErrors.length > 0){
			variantMultiErrors = uniqueArray(variantMultiErrors)
			msg[`variantError`] = `Please fill the variants [${variantMultiErrors.join("/")}]`;
		}

		console.info("variantCountWithError", variantCountWithError);

		if(variantCountWithError.length > 0)
			msg[`variantErrorCount`] = variantCountWithError.length;

		if(!props.title){
			passed = false;
			msg.title = 'Please fill the title'
		}

		if(!props.selectedMenu){
			passed = false;
			msg.menu = 'Please select the collection'
		}

		if(Object.keys(props.selectedBrand).length === 0){
			passed = false;
			msg.brand = 'Please select the brand'
		}

		/* if(!props.bulletsArea){
			passed = false;
			msg.bulletsArea = 'Please indicate a few lines'
		} */
		
		msg.descVideo = []
		msg.linkVideo = []

		for (let i = 0; i < props.nbrVideoMax; i++) {
			console.log($("#inputDescVideo_" + i).val());
			if(!$("#inputDescVideo_" + i).summernote('isEmpty') || props.linkVideo[i]){
				/* if($("#inputDescVideo_" + i).summernote('isEmpty')){
					passed = false;
					msg.descVideo[i] = "Please fill the description["+(i+1)+"] if you've already filled the link["+(i+1)+"]";
				} */
				if(!props.linkVideo[i]){
					passed = false;
					msg.linkVideo[i] = "Please fill the link["+(i+1)+"] if you've already filled the video description["+(i+1)+"]";
				}
			}
		}

		console.info("passed:", passed);
		console.info("msg:", msg);

		$('.hasTooltip').tooltip('dispose')

		if(!props.variantMode || warningRequiredForUnfilledVariantPosList.length === 0){
			callback({
				passed: passed,
				msg: msg
			})
		}else
			confirmation_modal(props, (closeModal) => {

				msg[`variantError`] = null

				// If ever the user agreed, we select only those variants fully validated
				if(listWithNoError.length > 0){
					msg = {}
					props.setVariantsSpecificsToSubmit(listWithNoError)
					passed = true
				}

				callback({
					passed: passed,
					msg: msg
				})

				closeModal()

			}, null, <div className="smallText font-weight-normal">
				[{warningRequiredForUnfilledVariantPosList.length}] variants not filled, this means that the option group selected won&apos;t be fully saved.
			<u className="mt-2 d-block">
				Shopify minimum requirement to create a variant is the price.
			</u>
		</div>)
	}

	function azureShippingRate(idItem, idVariant, isFreeShipping){
		if(!idItem || !idVariant)	return false

		let ratedGrp = "5454161346626"
		//, freeShippingGrp = "5235813285954"
			
		fetchAzure({
			"accName": "shopifyapp",
			"trigger": "fetch",
			"filters": "RowKey eq '" + idVariant + "' and idProduct eq '" + idItem + "' and idMeta eq '" + ratedGrp + "'",
		}, (r) => {
			console.info('Azure', r);
			console.info('isFreeShipping', isFreeShipping);

			if(r.res.length > 0){
				if(isFreeShipping)
					fetchAzure({
						"accName": "shopifyapp",
						"trigger": "delete",
						"partition": "itemsSelected",
						"key": idVariant
					}, (r) => {
						if(!r.success)
							props.msgAlert(r.error.message, false)
						console.info('Azure Delete Requested', r);
					})
			}else{
				if(!isFreeShipping)
					fetchAzure({
						"accName": "shopifyapp",
						"trigger": "insert",
						"table": "shipping",
						"data": JSON.stringify({
							"PartitionKey": "itemsSelected",
							"RowKey": idVariant.toString(),
							"idMeta": ratedGrp.toString(),
							"idProduct": idItem.toString(),
							"shopName": "eio-com.myshopify.com",
						})
					}, (r) => {
						console.info('Azure Insert Requested', r);
					})
			}
		})
	}

	const fetchAzure = (obj, callback) => {
		if(!obj.trigger){
			console.warn("Please indicate a trigger name for Azure.");
			return false
		}
		ajaxPerso( Object.assign({
			"api": "azure",
			"table": "shipping"
		}, obj), callback)
	}

	const bulletsAreaObject = () => {
		let output = [],
			isEmpty = props.bulletsArea.length > 0? false: true

		console.warn("bulletsArea", props.bulletsArea);

		output = props.bulletsArea.split("\n").map((line) => {
			return line.trim()
		})

		return {
			data: output,
			version: props.version,
			isEmpty: isEmpty
		}
	}

	const bulletsList = (value) => {
		if(value.split("\n").length <= 5){
			props.setBulletsArea(value)
		}else{
			props.info({error: "Bullets point are limited to 5"})
		}
	}

	const variantFilterValidData = () => {
		/**
		 * Return a list of valid variant, all the other not present in that list should be removed as variant.
		 */
		let listReturn = []
		props.variantsSpecificsToSubmit.filter((o) => {
			if("valFilled" in o && o.valFilled.price > 0.0 && o.valFilled.sku.length > 0 && o.valFilled.weight > 0.0){
				o.options.map((op, posOp) => {
					return o.valFilled[`option${posOp+1}`] = op
				})
				listReturn[o.valFilled.position-1] = o
			}
		})
		return listReturn
	}

	let lbWeight = "",
		ozWeight = ""

	if(props.variantsSpecificsToSubmit[props.selectedVariant])
		if(props.variantsSpecificsToSubmit[props.selectedVariant].valFilled.weight_unit == "lb")
			lbWeight = props.variantsSpecificsToSubmit[props.selectedVariant].valFilled.weight
		else
			ozWeight = props.variantsSpecificsToSubmit[props.selectedVariant].valFilled.weight

	console.info("props.selectedVariant", props.selectedVariant);
	let variantNbrSection = props.selectedVariant !== null? ` [Variant: ${props.selectedVariant}]` : ``

	return (
		<form onSubmit={(e) => { props.submitForm(e, controlValue, sendRequest); }}>
			<div>
				{
					props.msgInfo[`variantErrorCount`]? <span>Variant modified count: {props.msgInfo[`variantErrorCount`]}</span> : ""
				}
			</div>
			<div className="form-group hasTooltip mb-3 " { ...(props.msgInfo.title? { "data-toggle": "tooltipFormError", "title": props.msgInfo.title } : {}) }>
				<label htmlFor="forTitle">Title*</label>
				<input type="text" className="form-control" id="forTitle" aria-describedby="emailHelp" placeholder="Title" value={props.title || ""} onChange={(e) => {
					props.setTitle(e.target.value)
				}}/>
				{/* { props.msgInfo.title? <button className="text-danger d-none" data-toggle="tooltipFormError" title={props.msgInfo.title}>{props.msgInfo.title}</button> : ''} */}
			</div>
			<div className="form-group row">
				<div className={"col hasTooltip"} { ...(props.msgInfo[`sku${props.selectedVariant}`]? { "data-toggle": "tooltipFormError", "title": props.msgInfo[`sku${props.selectedVariant}`] } : {}) }>
					<label htmlFor="forSku">SKU*{variantNbrSection}
						{props.selectedBrand.prefix? <span className="ml-3" style={{fontSize: "12px"}}>Prefix automatically added: [{props.selectedBrand.prefix}]</span> : ""}
					</label>
					{ props.selectedBrand.prefix && props.needSkuPrefix? <div className="ml-3 mb-2 text-wrap" style={{fontSize: "12px"}}>
						a prefix exist for this vendor[{props.selectedBrand.prefix}] but wasn&apos;t detected in the sku
						<span className="ml-3 tooltipInfo" data-html="true" title="<span>
							This might happen if the sku was imported to shopify before our prefix introduction.
						</span>" data-toggle="tooltip"><FontAwesomeIcon icon={faQuestionCircle} style={{fontSize: "23px"}}/></span>
					</div> : "" }
					<input type="text" disabled={props.isAutoCreated} className="form-control" id="forSku" placeholder="SKU" value={props.removePrefix(props.variantsSpecificsToSubmit[props.selectedVariant].valFilled.sku)} onChange={(e) => {
						// setSku(e.target.value)
						props.searchExistingPrtoduct((props.selectedBrand.prefix ?? "") + e.target.value)
						props.variantsSpecificsToSubmit[props.selectedVariant].modified = true
						props.variantsSpecificsToSubmit[props.selectedVariant].valFilled.sku = (props.selectedBrand.prefix ?? "") + e.target.value
						props.setRefreshView(props.refreshView+1)
					}} /* onPaste={(e) => {
						props.searchExistingPrtoduct((window.event.clipboardData || window.clipboardData).getData('text'))
					}} *//>
				</div>
				<div className="col">
					<label htmlFor="forUpc">UPC{variantNbrSection}</label>
					<input type="text" disabled={props.isAutoCreated} className="form-control" id="forUpc" placeholder="UPC" value={props.variantsSpecificsToSubmit[props.selectedVariant].valFilled.barcode} onChange={(e) => {
						// setUpc(e.target.value)
						props.variantsSpecificsToSubmit[props.selectedVariant].modified = true
						props.variantsSpecificsToSubmit[props.selectedVariant].valFilled.barcode = e.target.value
						props.setRefreshView(props.refreshView+1)
					}}/>
				</div>
			</div>
			<div className="form-group row">
				<div className="col hasTooltip" { ...(props.msgInfo.mpn1? { "data-toggle": "tooltipFormError", "title": props.msgInfo.mpn1 } : {}) }>
					<label htmlFor="forMpn">
						Manufacture number*{variantNbrSection}
						{
							props.variantsSpecificsToSubmit[props.selectedVariant].valFilled.sku
							&& props.removePrefix(props.variantsSpecificsToSubmit[props.selectedVariant].valFilled.sku) != props.variantsSpecificsToSubmit[props.selectedVariant].valFilled.mpn?
								<button type="button" className="btn btn-sm btn-outline-secondary ml-2" title="change price to the MAP value" onClick={() => {
									
									let skuCleaned = props.variantsSpecificsToSubmit[props.selectedVariant].valFilled.sku
									if(skuCleaned.indexOf(props.selectedBrand.prefix) !== -1)
										skuCleaned = skuCleaned.substr(props.selectedBrand.prefix.length)

									props.variantsSpecificsToSubmit[props.selectedVariant].modified = true
									props.variantsSpecificsToSubmit[props.selectedVariant].valFilled.mpn = skuCleaned

									props.setRefreshView(props.refreshView+1)

								}}>
									Copy SKU
								</button>
							: ""
						}
					</label>
					<input type="text" className="form-control" id="forMpn" placeholder="Part number" value={props.variantsSpecificsToSubmit[props.selectedVariant].valFilled.mpn} onChange={(e) => {
						props.variantsSpecificsToSubmit[props.selectedVariant].modified = true
						props.variantsSpecificsToSubmit[props.selectedVariant].valFilled.mpn = e.target.value
						props.setRefreshView(props.refreshView+1)
					}}/>
					{/* { props.msgInfo.mpn? <button className="text-danger d-none" data-toggle="tooltipFormError" title={props.msgInfo.mpn}>{props.msgInfo.mpn}</button> : ''} */}
				</div>
				<div className="col hasTooltip" { ...(props.msgInfo[`weight${props.selectedVariant}`]? { "data-toggle": "tooltipFormError", "title": props.msgInfo[`weight${props.selectedVariant}`] } : {}) }>
					<label htmlFor="exampleInputEmail1">Weight*{variantNbrSection}</label>
					<div className="d-flex justify-content-center">
						<input type="number" step={0.1} className="form-control mr-1" placeholder="Lbs" style={{width: "110px"}} value={lbWeight} onChange={(e) => {
							// setLbs(parseFloat(e.target.value))
							props.variantsSpecificsToSubmit[props.selectedVariant].modified = true
							props.variantsSpecificsToSubmit[props.selectedVariant].valFilled.weight = parseFloat(e.target.value)
							props.variantsSpecificsToSubmit[props.selectedVariant].valFilled.weight_unit = "lb"
							props.setRefreshView(props.refreshView+1)
						}}/>
						<input type="number" step={0.1} className="form-control ml-1" placeholder="Oz" style={{width: "110px"}} value={ozWeight} onChange={(e) => {
							// setOz(parseFloat(e.target.value))
							props.variantsSpecificsToSubmit[props.selectedVariant].modified = true
							props.variantsSpecificsToSubmit[props.selectedVariant].valFilled.weight = parseFloat(e.target.value)
							props.variantsSpecificsToSubmit[props.selectedVariant].valFilled.weight_unit = "oz"
							props.setRefreshView(props.refreshView+1)
						}}/>
					</div>
				</div>
			</div>
			<div className="form-group row">
				{props.formSelectionCountry()}
				<div className="col">
					<label htmlFor="forQty">Quantity{variantNbrSection}</label>
					<input type="number" className="form-control" id="forQty" placeholder="number" value={props.variantsSpecificsToSubmit[props.selectedVariant].valFilled.inventory_quantity} onChange={(e) => {
						// setQty(e.target.value)
						props.variantsSpecificsToSubmit[props.selectedVariant].modified = true
						props.variantsSpecificsToSubmit[props.selectedVariant].valFilled.inventory_quantity = parseInt(e.target.value)
						props.setRefreshView(props.refreshView+1)
					}}/>
				</div>
			</div>
			<div className="form-group row noselect">
				<div className="col d-flex flex-column align-items-center ml-2">
					<div className="custom-control custom-switch noselect mb-3 w-100">
						<input type="checkbox" className="custom-control-input" id="customSwitchFree" checked={freeShipping === 1? true : false} onChange={(e) => { setFreeShipping(e.target.checked? 1 : 0) }}/>
						<label className="custom-control-label" htmlFor="customSwitchFree">Free shipping</label>
					</div>
					<div className="custom-control custom-switch noselect w-100">
						<input type="checkbox" className="custom-control-input" id="customSwitchIP" checked={props.inventoryPolicy === "continue"? true : false} onChange={(e) => { props.setInventoryPolicy(e.target.checked? "continue" : "deny") }}/>
						<label className="custom-control-label" htmlFor="customSwitchIP">Order when out of stock</label>
					</div>
				</div>
				<div className="col hasTooltip mt-2" { ...(props.msgInfo[`price${props.selectedVariant}`]? { "data-toggle": "tooltipFormError", "title": props.msgInfo[`price${props.selectedVariant}`] } : {}) }>
					<label htmlFor="forPrice">Price
						{variantNbrSection}
						{ ( props.itemFinale.map_price? <span className="ml-3" style={{fontSize: "12px"}}><button type="button" className="btn btn-sm btn-outline-secondary" title="change price to the MAP value" onClick={() => {
							// props.setPrice(parseFloat(props.itemFinale.map_price).toFixed(2))

							props.variantsSpecificsToSubmit[props.selectedVariant].modified = true
							props.variantsSpecificsToSubmit[props.selectedVariant].valFilled.price = parseFloat(props.itemFinale.map_price).toFixed(2)
							props.setRefreshView(props.refreshView+1)

						}}>MAP: { parseFloat(props.itemFinale.map_price).toFixed(2) }</button></span> : '' ) }
						{ ( props.itemFinale.cost? <span className="ml-3" style={{fontSize: "12px"}}>COST: { parseFloat(props.itemFinale.cost).toFixed(2) }</span> : '' ) }
					</label>
					<input type="number" step="0.01" className="form-control" id="forPrice" placeholder="Price" value={props.variantsSpecificsToSubmit[props.selectedVariant].valFilled.price} onChange={(e) => {
						// props.setPrice(parseFloat(e.target.value).toFixed(2))

						props.variantsSpecificsToSubmit[props.selectedVariant].modified = true
						props.variantsSpecificsToSubmit[props.selectedVariant].valFilled.price = e.target.value
						props.setRefreshView(props.refreshView+1)
					}}/>
					{/* { props.msgInfo.price? <button className="text-danger d-none" data-toggle="tooltipFormError" title={props.msgInfo.price}>{props.msgInfo.price}</button> : ''} */}
				</div>
			</div>
			<div className="form-group pb-2 hasTooltip" style={{maxWidth: "600px"}} { ...(props.msgInfo.bulletsArea? { "data-toggle": "tooltipFormError", "title": props.msgInfo.bulletsArea } : {}) }>
				<label htmlFor="inputBullets">Bullets list*<span className="ml-3 tooltipBullet" data-toggle="tooltip"><FontAwesomeIcon icon={faQuestionCircle} style={{fontSize: "23px"}}/></span></label>
				<textarea className="form-control" id="inputBullets" value={props.bulletsArea} onChange={(e) => bulletsList(e.target.value)}></textarea>
				{/* { props.msgInfo.bulletsArea? <button className="text-danger d-none" data-toggle="tooltipFormError" title={props.msgInfo.bulletsArea}>{props.msgInfo.bulletsArea}</button> : ''} */}
			</div>
			<div className="form-group pb-3" style={{maxWidth: "600px"}}>
				<label htmlFor="inputSortDesc">Short description</label>
				<textarea type="text" className="form-control" id="inputSortDesc" defaultValue={props.shortDesc}></textarea>
			</div>
			{/* <div className="form-group" style={{maxWidth: "600px"}}>
				<label htmlFor="inputDesc">Description</label>
				<textarea type="text" className="form-control" id="inputDesc" defaultValue={desc}></textarea>
			</div> */}
			<div className="form-group" style={{maxWidth: "600px", marginBottom: "100px"}}>
				{ props.formDefineVideoInfo() }
			</div>
			<div className="fixed-bottom text-center mb-1" style={{
				height: "1px"
			}}>
				<button type="submit" className="bgEio d-flex align-items-center justify-content-center text-center btn btn-lg btn-block d-inline-block text-white mx-auto position-relative" disabled={props.loading()} style={{width: "50%", opacity: '0.8', bottom: "50px"}}>
					<span>Submit</span>
					<img src={logoStoreEio} className="ml-4" style={{height: "35px"}}></img>
				</button>
			</div>
		</form>)

}

export default EioForm